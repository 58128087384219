import React from 'react';
import HistoryImage from "../../../images/on-campus_degrees/history.jpg";

const History = (props) => (
    <div>
        <div className="mb-8">
            <div className="offset-border-berry">
                <img src={HistoryImage} alt="Students participating in a group project" />
            </div>
        </div>
        <h2>History</h2>
        <p>To study history is to plan for the future. Through an in-depth review of time periods, cultures and economies, you’ll engage in a variety of opportunities to critique evidence and theories surrounding important events and better understand their impact on today’s world.</p>
        <p>History, one of the concentrations in SMC’s Bachelor of Arts program, will challenge you to think creatively and critically as you develop a variety of skills important to employers.</p>
        <h3>Why Study History?</h3>
        <p>Beyond its value as a discipline, history gives you an opportunity to conduct research, write persuasively and present effectively. All of which will enhance your performance in any career. In addition, you’ll have a unique insight into how past events have shaped today’s world.</p>
        <h3>Find Your Dream Job</h3>
        <p>When you pair your degree with the internships, soft skills and career development training you'll get at SMC, you can major in almost any subject(s) you love and be ready for a great career in nearly any industry that interests you. Explore examples of careers available to students concentrating in History:</p>
        <ul>
            <li>Archivist</li>
            <li>Journalist</li>
            <li>Teacher</li>
            <li>Librarian</li>
            <li>Lawyer</li>
            <li>Park Services</li>
            <li>Historian</li>
            <li>Law Enforcement</li>
        </ul>
        <div className="button-container">
            <h3>Want to learn more about SMC’s History degree?</h3>
            <a href="https://www.smcsc.edu/academics/degrees-programs/major-areas-of-study/history/" target="_blank" rel="noopener noreferrer"><button className="btn btn-primary btn-block" >Get More Info</button></a>        </div>
    </div>
);

export default History

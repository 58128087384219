import React from 'react';
import BaBusinessImage from "../../../images/online_degrees/baba-accounting.jpg";

const AccountingAndFinance = (props) => (
    <div>
        <div className="mb-8">
            <div className="offset-border-berry">
                <img src={BaBusinessImage} alt="Woman leading a meeting" />
            </div>
        </div>
        <h2>Bachelor of Arts in Business Administration – Accounting and Finance</h2>
        <p>A Business Administration degree in Accounting and Finance prepares students to move into a number of roles in tracking and reporting on financial activity, including the rules and regulations governing the profession. Graduates will be armed with the principles of finance and an overall knowledge of the financial aspects of business.</p>
        <p>Combined with the professional development and soft skills learned through SMC’s unique Camak Core program, graduates will be ready to step right into a new career and contribute immediately.</p>
        <h3>Why Get an Accounting and Finance Degree?</h3>
        <p>Business degrees are in high demand in today’s world. A degree in Accounting and Finance prepares graduates to tackle the challenging elements of a business’ financial position. You’ll learn the common practices and gain critical knowledge to provide your employer with the information and direction they need to make solid financial decisions.</p>
        <p>Since our program is flexible and affordable, you can gain the tools you need to enhance your career without interrupting it.</p>
        <h3>Find Your Dream Job</h3>
        <p>Career opportunities, on a national level, in business and financial operations are projected to grow faster than average for all occupations. Explore examples of careers available to student with a B.A. focused on Accounting and Finance:</p>
        <ul>
            <li>Corporate Accountant</li>
            <li>Government Accountant</li>
            <li>Financial Analyst</li>
            <li>Auditor</li>
            <li>Budget Analyst</li>
            <li>Tax Examiner</li>
            <li>Certified Public Accountant</li>
        </ul>
        <div className="button-container">
            <h3>Want to learn more about SMC’s Online Accounting and Finance degree?</h3>
            <a href="https://www.smcsc.edu/academics/spartanburg-methodist-college-online-degree-programs/online-bachelor-of-arts-in-business-administration-accounting-and-finance-concentration/" target="_blank" rel="noopener noreferrer"><button className="btn btn-primary btn-block">Get More Info</button></a>
        </div>
    </div>
);

export default AccountingAndFinance;

import React from 'react';
import EnglishImage from "../../../images/online_degrees/associate-arts.jpg";

const Arts = (props) => (
    <div>
        <div className="mb-8">
            <div className="offset-border-berry">
                <img src={EnglishImage} alt="Two students at a white board" />
            </div>
        </div>
        <h2>Associate in Arts</h2>
        <p>An Associate in Arts degree is designed as a starting point for students who plan to pursue a bachelor’s degree or be considered for entry-level positions. Our degree provides a general studies education that can help guide you into the field of study you enjoy.</p>
        <h3>Why Get an Associate in Arts Degree?</h3>
        <p>If your goal is to begin building the future you want, save money on tuition, or explore a variety of subjects before settling on one, then earning an Associate in Arts degree is a great decision. You can also expect a median salary increase up to 18% higher than someone with only a high school diploma, according to the Bureau of Labor Statistics.</p>
        <h3>Find Your Dream Job</h3>
        <p>Your degree will prepare you to transfer into a four-year bachelor's degree program to further your education. It will also prepare you to move directly into an entry-level position to get your career started. Explore examples of careers available to students with an Associate of Arts:</p>
        <ul>
            <li>Preschool teacher</li>
            <li>Operations manager</li>
            <li>Administrative assistant</li>
            <li>Human Resources</li>
            <li>Customer service representative</li>
            <li>And more!</li>
        </ul>
        <div className="button-container">
            <h3>Want to learn more about SMC’s Online Associate in Arts degree?</h3>
            <a href="https://www.smcsc.edu/academics/degrees-programs/online-associate-in-arts/ " target="_blank" rel="noopener noreferrer"><button className="btn btn-primary btn-block">Get More Info</button></a>
        </div>
    </div>
);

export default Arts;

import React from 'react';
import BusinessImage from "../../../images/on-campus_degrees/business.jpg";

const Business = (props) => (
    <div>
        <div className="mb-8">
            <div className="offset-border-berry">
                <img src={BusinessImage} alt="Two students at a white board" />
            </div>
        </div>
        <h2>Business</h2>
        <p>From the products and services you buy to the entertainment you enjoy, business is an integral part of daily life. The people behind successful, efficient companies have a deep understanding of the policies and procedures that impact consumer purchasing decisions.</p>
        <p>A business concentration, paired with one of our other concentrations, will prepare you to start your own company or excel in a variety of careers within corporations and nonprofit organizations.</p>
        <h3>Why Study Business?</h3>
        <p>Through the study of business, you’ll develop the crucial critical thinking and communication skills necessary to make decisions as an employee and a leader. In addition, the SMC business concentration emphasizes financial literacy, an important asset employers seek in new hires, but also helpful in understanding and managing your personal finances.</p>
        <h3>Find Your Dream Job</h3>
        <p>When you pair your degree with the internships, soft skills and career development training you'll get at SMC, you can major in almost any subject(s) you love and be ready for a great career in nearly any industry that interests you. Explore examples of careers available to students concentrating in Business:</p>
        <ul>
            <li>Accounting</li>
            <li>Advertising</li>
            <li>Banking, investment, and financial services</li>
            <li>Human Resources</li>
            <li>Management</li>
            <li>Public Relations</li>
            <li>Marketing</li>
            <li>Sales</li>
        </ul>
        <div className="button-container">
            <h3>Want to learn more about SMC’s Business degree?</h3>
            <a href="https://www.smcsc.edu/academics/degrees-programs/major-areas-of-study/business/" target="_blank" rel="noopener noreferrer"><button className="btn btn-primary btn-block">Get More Info</button></a>
        </div>
    </div>
);

export default Business

import React, { Component } from "react";
import moment from "moment";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axios from "axios/index";
import * as ROUTES from "../../constants/routes";
import ScrollToTop from "./ScrollTop";
import Academics from "../Academics";
import Campus from "../Campus";
import CareerDevelopment from "../CareerDevelopment";
import FinancialAid from "../FinancialAid";
import Home from "../Home";
import NoMatch from "../NoMatch";
import Spartanburg from "../Spartanburg";
import Navigation from "../Navigation";
import Footer from "../Footer";
import OnCampusDegrees from "../OurDegrees/OnCampus";
import OnlineDegrees from "../OurDegrees/Online";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userFormError: false,
            userFormSubmitted: false,
            user: {
                firstName: "",
                lastName: "",
                email: "",
                mobilePhone: "",
                smsNotifications: false,
                birthMonth: "",
                birthDay: "",
                birthYear: "",
                programType: "",
                programOfInterest: "",
                expectedEntryTerm: "",
                studentType: "first-year"
            }
        };
        this.getUserDetails = this.getUserDetails.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getUserDetails(purlID) {
        axios
            .post()
            .then(response => {
                let year = moment(
                    response.data.records[0].Birthdate,
                    "YYYY-MM-DD"
                ).format("YYYY");

                let day = moment(
                    response.data.records[0].Birthdate,
                    "YYYY-MM-DD"
                ).format("DD");

                let month = moment(
                    response.data.records[0].Birthdate,
                    "YYYY-MM-DD"
                ).format("MM");

                let user = {
                    firstName: response.data.records[0].FirstName,
                    lastName: response.data.records[0].LastName,
                    email: response.data.records[0].Email,
                    phone: response.data.records[0].Phone,
                    birthYear: year,
                    birthDay: day,
                    birthMonth: month,
                    responseLoaded: true
                };

                this.setState({
                    user: user
                });
                return true;
            })
            .catch(() => {
                return true;
            });
    }

    handleInputChange(e) {
        const user = Object.assign(this.state.user, {});
        user[e.target.name] = e.target.value;
        this.setState({ user: user });
    }

    handleSubmit(e) {
        e.preventDefault();
        let user = this.state.user;
        //let dob = user.birthMonth + "/" + user.birthDay + "/" + user.birthYear;
        if (user.programType && user.firstName && user.lastName && user.email) {
            let userDetails = {
                first: user.firstName,
                last: user.lastName,
                email: user.email,
                phone: user.phone,
                smsNotification: user.smsNotifications,
                //dob: dob,
                programType: user.programType,
                programOfInterest: user.programOfInterest,
                expectedEntryTerm: user.expectedEntryTerm,
                studentType: user.studentType
            };

            axios({
                method: "POST",
                url: "https://slate.mediacross.com/smc-viewbook/update",
                data: userDetails
            })
                .then(function (response) {
                    if (response.error) {
                        console.err("ERROR: " + response.error);
                        this.setState({ submitError: true });
                    } else {
                        console.log("Send successful");
                    }
                })
                .catch(function (err) {
                    console.error(err);
                });

            this.setState({ userFormSubmitted: true });
        } else {
            this.setState({
                userFormError: true
            });
        }
    }

    render() {
        return (
            <Router>
                <ScrollToTop>
                    <Navigation />
                    <Routes>
                        <Route
                            path={ROUTES.OUR_DEGREES}
                            element={<OnCampusDegrees
                                user={this.state.user}
                                handleInputChange={this.handleInputChange}
                                handleSubmit={this.handleSubmit}
                                submitError={this.state.userFormError}
                                userFormSubmitted={
                                    this.state.userFormSubmitted
                                }
                            />}
                        />
                        <Route
                            path={ROUTES.ACADEMICS}
                            element={<Academics />}
                        />
                        <Route
                            path={ROUTES.ONLINE_DEGREES}
                            element={<OnlineDegrees
                                user={this.state.user}
                                handleInputChange={this.handleInputChange}
                                handleSubmit={this.handleSubmit}
                                submitError={this.state.userFormError}
                                userFormSubmitted={
                                    this.state.userFormSubmitted
                                }
                            />}
                        />
                        <Route path={ROUTES.CAMPUS} element={<Campus />} />
                        <Route
                            path={ROUTES.CAREER_DEVELOPMENT}
                            element={<CareerDevelopment
                                user={this.state.user}
                                handleInputChange={this.handleInputChange}
                                handleSubmit={this.handleSubmit}
                                submitError={this.state.userFormError}
                                userFormSubmitted={
                                    this.state.userFormSubmitted
                                }
                            />}
                        />
                        <Route
                            path={ROUTES.FINANCIAL_AID}
                            element={<FinancialAid
                                user={this.state.user}
                                handleInputChange={this.handleInputChange}
                                handleSubmit={this.handleSubmit}
                                submitError={this.state.userFormError}
                            />}
                        />
                        <Route
                            path={ROUTES.HOME}
                            element={<Home
                                user={this.state.user}
                                handleInputChange={this.handleInputChange}
                                handleSubmit={this.handleSubmit}
                                submitError={this.state.userFormError}
                                userFormSubmitted={
                                    this.state.userFormSubmitted
                                }
                            />}
                        />
                        <Route
                            path={ROUTES.SPARTANBURG}
                            element={<Spartanburg />}
                        />
                        <Route
                            element={<NoMatch
                                getUserDetails={this.getUserDetails}
                            />}
                        />
                    </Routes>
                    <Footer />
                </ScrollToTop>
            </Router>
        );
    }
}

export default App;

import { useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';

function ScrollToTop(props) {
    let location = useLocation();
    const prevLocation = useRef('');

    useEffect(() => {
        if (location.pathname !== prevLocation.current) {
            window.scrollTo(0, 0);
            prevLocation.current = location.pathname;
        }
    }, [location]);

    return <div>{props.children}</div>;
}

export default ScrollToTop;

import React from 'react';
import BaBusinessImage from "../../../images/online_degrees/baba-management.jpg";

const ManagementAndEntrepreneurship = (props) => (
    <div>
        <div className="mb-8">
            <div className="offset-border-berry">
                <img src={BaBusinessImage} alt="A student writes on a whiteboard" />
            </div>
        </div>
        <h2>Bachelor of Arts in Business Administration – Management and Entrepreneurship</h2>
        <p>A Business Administration degree in Management and Entrepreneurship is designed to give students the tools and knowledge needed to become leaders in their companies or to start their own business. Graduates will have the skills needed to be confident in their ability to lead teams and make difficult business decisions.</p>
        <p>Combined with the professional development and soft skills learned through SMC’s unique Camak Core program, graduates will be ready to step right into a new career and contribute immediately.</p>
        <h3>Why Get a Management and Entrepreneurship Degree?</h3>
        <p>Business degrees are in high demand in today’s world. A degree in Management and Entrepreneurship prepares graduates to become leaders. You’ll gain knowledge in all aspects of running a business, including finance, data analysis, organizational effectiveness and strategic planning.</p>
        <p>Since our program is flexible and affordable, you can gain the tools you need to enhance your career without interrupting it.</p>
        <h3>Find Your Dream Job</h3>
        <p>Career opportunities, on a national level, in business and financial operations are projected to grow faster than average for all occupations. Explore examples of careers available to students with a B.A. focused on Management and Entrepreneurship:</p>
        <ul>
            <li>Business Administration</li>
            <li>Company Director or Manager</li>
            <li>Management Consulting</li>
            <li>Finance</li>
            <li>Banking</li>
            <li>Business Start-Up</li>
        </ul>
        <div className="button-container">
            <h3>Want to learn more about SMC’s Online Management and Entrepreneurship degree?</h3>
            <a href="https://www.smcsc.edu/academics/spartanburg-methodist-college-online-degree-programs/online-bachelor-of-arts-in-business-administration-management-and-entrepreneurship-concentration/ " target="_blank" rel="noopener noreferrer"><button className="btn btn-primary btn-block">Get More Info</button></a>
        </div>
    </div>
);

export default ManagementAndEntrepreneurship;

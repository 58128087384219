import React from 'react';
import CriminalJusticeImage from "../../../images/online_degrees/associate-criminal_justice.jpg";

const CriminalJustice = () => (
    <div>
        <div className="mb-8">
            <div className="offset-border-berry">
                <img src={CriminalJusticeImage} alt="A police officer standing outside her vehicle" />
            </div>
        </div>
        <h2>Associate in Criminal Justice</h2>
        <p>Centered around critical thinking skills, careers in criminal justice are as rewarding as they are varied. Working in a criminal justice-related job requires you to solve complex problems while advocating for justice.</p>
        <p>A criminal justice degree is the ideal path for those interested in making an impact on the lives of others. The study of criminal justice can prepare you for law school or a variety of law enforcement training opportunities.</p>
        <h3>Why Study Criminal Justice?</h3>
        <p>Beyond the development of essential critical thinking and problem-solving skills, you’ll learn to think quickly and communicate clearly. In addition, studying criminal justice positions you for a stable career in a high-growth field.</p>
        <h3>Find Your Dream Job</h3>
        <p>Your degree will prepare you to move directly into a career in criminal justice or a law enforcement academy. It is also a direct line to our Bachelor of Arts - Criminal Justice program, partnered with a concentration in either business or psychology, to advance your education and knowledge. Potential careers open to criminal justice graduates include:</p>
        <ul>
            <li>Lawyer</li>
            <li>Paralegal</li>
            <li>Victim’s Advocate</li>
            <li>Forensic Psychologist</li>
            <li>Probation Officer</li>
            <li>Police Officer</li>
        </ul>
        <div className="button-container">
            <h3>Want to learn more about SMC’s Online Associate in Criminal Justice degree?</h3>
            <a href="https://www.smcsc.edu/academics/degrees-programs/online-associate-in-criminal-justice-degree/" target="_blank" rel="noopener noreferrer"><button className="btn btn-primary btn-block" >Get More Info</button></a>
        </div>
    </div>
);

export default CriminalJustice;

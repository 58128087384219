import React, {Component} from 'react';
import { Helmet } from "react-helmet";

import './Spartanburg.scss';
import HeroImage from "../../images/spartanburg_living/hero.jpg"
import { ReactComponent as Jumping } from "../../images/spartanburg_living/jumping.svg"
import AccessibleImg from '../../images/spartanburg_living/accessible.jpg';
import BestImg from '../../images/spartanburg_living/best.jpg';
import ExcitingImg from '../../images/spartanburg_living/exciting.jpg';
import { ReactComponent as TopoOrange } from "../../images/topographical-lines-orange.svg"

import SpartanburgMap from './Map';

class Spartanburg extends Component {
    render() {
        return (
            <div className="spartanburg">
                <Helmet>
                    <title>Spartanburg | SMC Lookbook</title>
                    <meta name="description" content="Once a bustling railroad hub, Spartanburg has evolved into a modern,
                        mid-sized metropolis filled with great places to eat, shop and play." />
                    <meta name="keywords" content="#8 Small Metro nationwide, 17.8% millennial population growth over five years,
                        hike, bike, fish, camp, Paris Mountain, Kings Mountain, Croft State Park, Greenville, Ashville, Carowinds" />
                    <link rel="canonical" href="https://makebigmoves.smcsc.edu/campus/spartanburg" />
                    <meta property="og:title" content="Spartanburg | SMC Lookbook" />
                    <meta property="og:description" content="Once a bustling railroad hub, Spartanburg has evolved into a modern
                        , mid-sized metropolis filled with great places to eat, shop and play." />
                    <meta property="og:image" content="/og-image-home.png" />
                    <meta property="og:url" content="https://makebigmoves.smcsc.edu/campus/spartanburg" />
                    <meta property="twitter:title" content="Spartanburg | SMC Lookbook" />
                    <meta property="twitter:description" content="Once a bustling railroad hub, Spartanburg has evolved into a
                        modern, mid-sized metropolis filled with great places to eat, shop and play." />
                    <meta property="twitter:image" content="/og-image-home.png" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>

                <div className="photo-hero-container mb-8">
                    <img src={HeroImage} alt="Aerial vantage point of Spartanburg, South Carolina" />
                    <div className="hero-topo">
                        <TopoOrange />
                    </div>
                </div>

                <div className="container text-center mb-8">
                    <div className="mb-4">
                        <h2>Spartanburg Living</h2>
                        <h3>Hub City Here You Come</h3>
                    </div>
                    <p>Spartanburg is a city on the move. Once a bustling railroad hub, Spartanburg has evolved into a modern, mid-sized metropolis filled with great places to eat, shop and play.</p>
                </div>

                <div className="container mb-8">
                    <div className="fw-line-art p-1">
                        <Jumping />
                    </div>
                </div>

                <div className="items-row text-center mb-8">
                    <div className="item">
                        <div className="offset-border-berry">
                            <img src={BestImg} alt="" />
                        </div>
                        <h3>Best</h3>
                        <p>In 2021, U.S. News and World Report of Best Places to Live ranks Spartanburg #1 in South Carolina and #24 in the U.S.</p>
                    </div>
                    <div className="item">
                        <div className="offset-border-blue">
                            <img src={ExcitingImg} alt="" />
                        </div>
                        <h3>Exciting</h3>
                        <p>Get outside and hike, bike, fish, or camp at Paris Mountain, Kings Mountain and Croft State Parks nearby.</p>
                    </div>
                    <div className="item">
                        <div className="offset-border-lime">
                            <img src={AccessibleImg} alt="" />
                        </div>
                        <h3>Accessible</h3>
                        <p>Greenville, Asheville, Charleston and Charlotte are an easy drive away for a day or a weekend.</p>
                    </div>
                </div>

                <SpartanburgMap key="map" />
            </div>
        );
    }
}

export default Spartanburg;

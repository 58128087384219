import React, { Component } from "react";
import "./UserForm.scss";
import UserFormInputs from "./Form";

class UserForm extends Component {
    render() {
        return (
            <div className="user-form-container">
                <div className="user-form-text">
                    <h2>Make the move</h2>
                    <p>
                        {this.props.introText}
                    </p>
                </div>
                {this.props.submitted ? (
                    <div className="user-form-submitted">
                        <h2>
                            Thank you for submitting your information. We will
                            be reaching out to you soon!
                        </h2>
                        {this.props.user.studentType === "transfer" && (
                            <h2>
                                SMC is proud to host Transfer Tuesday events
                                every third Tuesday each month. Learn about our
                                innovative Bachelor of Arts degree program, get
                                a campus tour, have dinner in the campus dining
                                hall (on us!) and while you dine, we'll process
                                your application and let you know if you're
                                accepted!
                                <a href="https://www.smcsc.edu/transfertuesday">
                                    Register now!
                                </a>
                            </h2>
                        )}
                    </div>
                ) : (
                        <div className="user-form-group">
                            <UserFormInputs
                                formClass="user-form"
                                handleUserForm={this.props.handleSubmit}
                                user={this.props.user}
                                handleInputChange={this.props.handleInputChange}
                                error={this.props.submitError}
                                buttonText="Submit"
                            />
                        </div>
                    )}
            </div>
        );
    }
}

export default UserForm;

import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import '../OurDegrees.scss';
import HeroImage from "../../../images/online_degrees/hero.jpg"
import JesicaHammImg from "../../../images/testimonials/jesica-hamm-headshot-berry-outline.png"
import { ReactComponent as TopoBerry } from "../../../images/topographical-lines-berry.svg"
import { ReactComponent as TopoOrange } from "../../../images/topographical-lines-orange.svg"

import Concentrations from "../Concentrations";
import UserForm from '../../UserForm';
import Arts from "./Arts";
import AABusiness from './AABusiness';
import CriminalJustice from './CriminalJustice';
import AccountingAndFinance from "./AccountingAndFinance";
//import EconomicsAndFinance from "./EconomicsAndFinance";
import ManagementAndEntrepreneurship from "./ManagementAndEntrepreneurship";
import MarketingAndSales from "./MarketingAndSales";
import BACriminalJustice from "./BACriminalJustice";
import BABusiness from "./BABusiness";
import BAPsychology from "./BAPsychology";


class OnlineDegrees extends Component {
    render() {
        let concentrations = [
            {name: "Associate in Arts Degree", component: <Arts />},
            {name: "Associate in Business Degree", component: <AABusiness />},
            {name: "Associate in Criminal Justice Degree", component: <CriminalJustice />},
            {name: "Bachelor of Arts - Business", component: <BABusiness /> },
            {name: "Bachelor of Arts - Criminal Justice", component: <BACriminalJustice /> },
            {name: "Bachelor of Arts - Psychology", component: <BAPsychology /> },
            {name: "Bachelor of Arts in Business Administration - Accounting and Finance", component: <AccountingAndFinance /> },
            //{name: "Bachelor of Arts in Business Administration (BABA) - Economics and Finance", component: <EconomicsAndFinance /> },
            {name: "Bachelor of Arts in Business Administration - Management and Entrepreneurship", component: <ManagementAndEntrepreneurship /> },
            {name: "Bachelor of Arts in Business Administration - Marketing and Sales", component: <MarketingAndSales /> },
        ]
        let concentrationIntro = () => {
            return (<div>
                <h2 style={{marginBottom: "2.4rem" }}>Choosing an Online Degree <br/>that Meets Your Needs</h2>
                <p>With flexibility, affordability, and a new term every 8 weeks, the time is always right to Make Big Moves by starting your journey at SMC Online!</p>
            </div>)
        }
        return (
            <main className="academics" id="main" tabIndex="-1">
                <Helmet>
                    <title>Online Degrees | SMC Lookbook</title>
                    <meta name="description" content="At SMC you have choices: 2-year or 4-year, on campus or online. With a range of degree options and concentrations, at SMC you carve your own path." />
                    <meta name="keywords" content="online, online degree, 2-year, two year, associate degrees, arts, business, criminal justice, fine
                        arts, religious studies, science, 4-year, four year, bachelor's degrees, business, english, history,
                        religion" />
                    <link rel="canonical" href="https://makebigmoves.smcsc.edu/academics/our-online-degrees" />
                    <meta property="og:title" content="Online Degrees | SMC Lookbook" />
                    <meta property="og:description" content="At SMC you have choices: 2-year or 4-year, on campus or online. With a range of degree options and concentrations, at SMC you carve your own path." />
                    <meta property="og:image" content="/og-image-home.png" />
                    <meta property="og:url" content="https://makebigmoves.smcsc.edu/academics/our-online-degrees" />
                    <meta property="twitter:title" content="Online Degrees | SMC Lookbook" />
                    <meta property="twitter:description" content="At SMC you have choices: 2-year or 4-year, on campus or online. With a range of degree options and concentrations, at SMC you carve your own path." />
                    <meta property="twitter:image" content="/og-image-home.png" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>

                <div className="photo-hero-container mb-8">
                    <img src={HeroImage} alt="College students attending a graduation ceremony" />
                    <div className="hero-topo">
                        <TopoOrange />
                    </div>
                </div>

                <div className="container text-center mb-4">
                    <h2>Our Online Degree Programs</h2>
                    <h3 className="mb-4">You have choices</h3>
                    <p>SMC offers certificates, 2-year, and 4-year degrees online that help you achieve your career goals in manageable steps. Whether you are looking to start or complete your degree, we know SMC’s 100% online programs will provide the flexible format to meet you wherever you are in life, allowing you to learn around your own schedule and at your own pace.</p>
                </div>

                <div className="topo mb-8">
                    <TopoBerry />
                </div>

                <div className="container text-center mb-8">
                    <div className="mb-8">
                        <div className="mb-8">
                            <h2><span className="blue-slant">2-Year Online Associate Degree</span></h2>
                            <p>A 2-year online associate degree puts you on the path to bigger opportunities and better jobs. It’s a smart, affordable way to start exploring your career interests with confidence.</p>
                            <h4>Associate Degree Programs</h4>
                            <ul>
                                <li>Arts (General Studies)</li>
                                <li>Business (Pathway to Bachelor of Arts in Business Administration)</li>
                                <li>Criminal Justice (Pathway to Bachelor of Arts - Criminal Justice)</li>
                            </ul>
                        </div>

                        <div>
                            <h2><span className="orange-slant">4-Year Online Bachelor’s Degree</span></h2>
                            <p>Whether you started at SMC or need to complete your degree, SMC’s online, career-ready bachelor’s degrees are designed to prepare you for careers available today AND in the future.</p>
                            <h4>Bachelor's Degree Programs</h4>
                            <ul>
                                <li>Bachelor of Arts in Business Administration – Accounting and Finance</li>
                                {/*<li>Bachelor of Arts in Business Administration – Economics and Finance</li>*/}
                                <li>Bachelor of Arts in Business Administration – Management and Entrepreneurship</li>
                                <li>Bachelor of Arts in Business Administration – Marketing and Sales</li>
                                <li>Bachelor of Arts - Business</li>
                                <li>Bachelor of Arts - Criminal Justice</li>
                                <li>Bachelor of Arts - Psychology</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <Concentrations
                    kind="online"
                    concentrations={concentrations}
                    concentrationIntro={concentrationIntro()}
                    dropdownLabel={"Learn more about our online degrees"}
                />

                <UserForm
                    key="user-form"
                    introText="With an emphasis on a format that is flexible and affordable, our online programs will equip you with the tools to advance your education in the comfort of your own home!"
                    user={this.props.user}
                    handleSubmit={this.props.handleSubmit}
                    handleInputChange={this.props.handleInputChange}
                    submitError={this.props.submitError}
                    submitted={this.props.userFormSubmitted}
                />

                <div className="topo mb-8">
                    <TopoBerry />
                </div>

                <div className="testimonial bg-orange mb-8">
                    <img src={JesicaHammImg} alt="Jesica Hamm" />
                    <div>
                        <p className="testimonial-name">Jesica Hamm</p>
                        <p className="testimonial-class mb-4">SMC Class 2021</p>
                        <p className="testimonial-text mb-4">Using the writing and communications skills she learned through her English and history concentrations, Jesica obtained a summer internship in the marketing department at Polydeck — a leading provider of polyurethane and rubber screen media.</p>
                        <p className="testimonial-quote">“What I’ve liked most about my marketing internship is the constant growth I've had in an area I knew nothing about. SMC and Polydeck have continuously worked to find the best fit for me, which has allowed me strengthen myself professionally and further my goals.”</p>
                    </div>
                </div>
            </main>
        );
    }
}

export default OnlineDegrees;

import React from 'react';
import BusinessImage from "../../../images/online_degrees/associate-business.jpg";

const AABusiness = (props) => (
    <div>
        <div className="mb-8">
            <div className="offset-border-berry">
                <img src={BusinessImage} alt="A student sitting with his laptop" />
            </div>
        </div>
        <h2>Associate in Business</h2>
        <p>From the products and services you buy to the entertainment you enjoy, business is an integral part of daily life. The people behind successful, efficient companies have a deep understanding of the policies and procedures that impact consumer purchasing decisions.</p>
        <p>An Associate in Business will prepare you to start your own company or excel in a variety of careers within corporations and nonprofit organizations.</p>
        <h3>Why Study Business?</h3>
        <p>Through the study of business, you’ll develop the crucial critical thinking and communication skills necessary to make decisions as an employee and a leader. In addition, the SMC Associate in Business emphasizes financial literacy, an important asset employers seek in new hires, but also helpful in understanding and managing your personal finances.</p>
        <h3>Find Your Dream Job</h3>
        <p>Your degree will prepare you to transfer into the four-year Bachelor of Arts in Business Administration program to further your education and business knowledge. It will also prepare you to move directly into an entry-level position to get your career started. Explore examples of careers available to students concentrating in Business:</p>
        <ul>
            <li>Sales Associate</li>
            <li>Office Manager</li>
            <li>Real Estate Agent</li>
            <li>Marketing Associate</li>
            <li>Human Resources Representative</li>
        </ul>
        <div className="button-container">
            <h3>Want to learn more about SMC’s Online Business degree?</h3>
            <a href="https://www.smcsc.edu/academics/spartanburg-methodist-college-online-degree-programs/associate-business/" target="_blank" rel="noopener noreferrer"><button className="btn btn-primary btn-block">Get More Info</button></a>
        </div>
    </div>
);

export default AABusiness

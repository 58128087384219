import React, { Component } from "react";
import { Helmet } from "react-helmet";

import "./CareerDevelopment.scss";
import UserForm from '../UserForm';
import HeroImage from "../../images/career_development/hero.jpg"
import { ReactComponent as JobInterview } from '../../images/career_development/job-interview.svg';
import InternshipsImg from '../../images/career_development/internships.jpg';
import AdvisorsImg from '../../images/career_development/advisors.jpg';
import CamakCoreImg from '../../images/career_development/camak-core.jpg';
import { ReactComponent as TopoBerry } from "../../images/topographical-lines-berry.svg"
import { ReactComponent as TopoOrange } from "../../images/topographical-lines-orange.svg"

class CareerDevelopment extends Component {
    render() {
        return (
            <main className="career-development" id="main" tabIndex="-1">
                <Helmet>
                    <title>Career Development | SMC Lookbook</title>
                    <meta name="description" content="Things move quickly in the work world. That’s why we include career
                        development as part of what you’ll learn at SMC." />
                    <meta name="keywords" content="Strong Written and Oral Communication Skills, Effective Time Management
                        Ability, Applied Technology Training, Collaborative Problem-Solving Experience, internships, collaborative
                        spaces, CAMAK Core" />
                    <link rel="canonical" href="https://makebigmoves.smcsc.edu/academics/career-development" />
                    <meta property="og:title" content="Career Development | SMC Lookbook" />
                    <meta property="og:description" content="Things move quickly in the work world. That’s why we include career
                        development as part of what you’ll learn at SMC." />
                    <meta property="og:image" content="/og-image-home.png" />
                    <meta property="og:url" content="https://makebigmoves.smcsc.edu/academics/career-development" />
                    <meta property="twitter:title" content="Career Development | SMC Lookbook" />
                    <meta property="twitter:description" content="Things move quickly in the work world. That’s why we include
                        career development as part of what you’ll learn at SMC." />
                    <meta property="twitter:image" content="/og-image-home.png" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>

                <div className="photo-hero-container mb-8">
                    <img src={HeroImage} alt="College student in an internship" />
                    <div className="hero-topo">
                        <TopoBerry />
                    </div>
                </div>

                <div className="container text-center mb-4">
                    <div className="mb-4">
                        <h2>Ready</h2>
                        <h3>To Work</h3>
                    </div>
                    <p>Things move quickly in the work world. That’s why we include career development as part of what you’ll learn at SMC. It’s all about giving you an edge. After you graduate and it’s time to dive into a career, you’ll be more than ready to make big moves.</p>
                </div>

                <div className="employers-want-container mb-8">
                    <div className="employers-want-left fw-line-art p-4">
                        <JobInterview />
                    </div>
                    <div className="employers-want-right">
                        <div className="employers-want-text">
                            <h2>What Employers Want</h2>
                            <ul>
                                <li>Strong Written and Oral Communication Skills</li>
                                <li>Effective Time Management Ability</li>
                                <li>Applied Technology Training</li>
                                <li>Collaborative Problem-Solving Experience</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="topo mb-8">
                    <TopoOrange />
                </div>

                <div className="container text-center mb-4">
                    <h2>What We're Doing About It</h2>
                </div>
                <div className="items-row text-center mb-8">
                    <div className="item">
                        <div className="offset-border-berry">
                            <img src={InternshipsImg} alt="Internships" />
                        </div>
                        <h3>Internships</h3>
                        <p>Learn, refine and master what it takes to succeed in competitive office environments or in the wild world of entrepreneurship.</p>
                    </div>
                    <div className="item">
                        <div className="offset-border-blue">
                            <img src={AdvisorsImg} alt="All-in-One Advisors" />
                        </div>
                        <h3>All-in-One Advisors</h3>
                        <p>You'll have an academic, career and success advisor all in one person. They'll know your story and can answer all your questions, all four years.</p>
                    </div>
                    <div className="item">
                        <div className="offset-border-lime">
                            <img src={CamakCoreImg} alt="Camak Core" />
                        </div>
                        <h3>Camak Core</h3>
                        <p>Stand out to employers after graduation with 18 hours of professional development training under your belt.</p>
                    </div>
                </div>

                <UserForm
                    key="user-form"
                    introText="SMC just makes sense. We’re seeking students from all backgrounds. So, bring your strengths, your experiences and your passion and let’s make some BIG MOVES."
                    user={this.props.user}
                    handleSubmit={this.props.handleSubmit}
                    handleInputChange={this.props.handleInputChange}
                    submitError={this.props.submitError}
                    submitted={this.props.userFormSubmitted}
                />
            </main>
        );
    }
}

export default CareerDevelopment;

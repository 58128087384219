export const HOME = '/';
export const OUR_DEGREES = '/on-campus';
export const ONLINE_DEGREES = '/online';
export const ACADEMICS = '/academics';
export const CAREER_DEVELOPMENT = '/career-development';
export const CAMPUS = '/campus/living';
export const SPARTANBURG = '/campus/spartanburg';
export const FINANCIAL_AID = '/financial-aid';
export const CLUBS = 'https://www.smcsc.edu/campus-life/studentactivities/clubs-organizations/';
export const VISIT = 'https://www.smcsc.edu/admissions/plan-a-visit/';
export const APPLY = 'https://www.smcsc.edu/admissions/apply/';
export const PIONEERS = 'https://www.gosmcpioneers.com';
export const WEBSITE = 'https://www.smcsc.edu';

import React from 'react';

const CalculatorScoresForm = (props) => (
    <form className={`scores-form${props.error ? ' error' : ''}`} onSubmit={props.handleCalculate}>
        <div className="input-group">
            <label htmlFor="gpa">What is your GPA?</label>
            <div className="select-group">
                <select name="gpa" id="gpa" onChange={props.handleChange}>
                    <option value="">I don't know this</option>
                    <option value="4">4.00 or Higher</option>
                    <option value="3.75">3.75 to 3.99</option>
                    <option value="3.5">3.50 to 3.74</option>
                    <option value="3.25">3.25 to 3.49</option>
                    <option value="3.0">3.00 to 3.24</option>
                    <option value="2">2.00 to 2.99</option>
                    <option value="1">1.99 or lower</option>
                </select>
            </div>
        </div>
        <div className="input-group">
            <label className="checkbox"><input type="checkbox" name="resident" id="resident" onChange={props.handleChange} /> Are you a South Carolina resident?</label>
        </div>
        {props.error === true && (
            <div className="alert warning">
                <p aria-live="assertive" tabIndex="-1">
                    <strong>Oops!</strong> We need at least one of
                    your scores for the calculator.
                            </p>
            </div>
        )}
        <button id="scoresForm" className="btn btn-primary btn-block" type="submit">Calculate</button>
    </form>
);

export default CalculatorScoresForm;

import React from 'react';
import PsychologyImage from "../../../images/on-campus_degrees/psychology.jpg";

const Psychology = (props) => (
    <div>
        <div className="mb-8">
            <div className="offset-border-berry">
                <img src={PsychologyImage} alt="Students gathered around a labtop" />
            </div>
        </div>
        <h2>Psychology</h2>
        <p>The study of psychology gives you a deep understanding of human behavior and motivation. It seeks to find answers to questions that improve the way we live our lives and interact with others. In addition, studying psychology will help you get to know yourself better.</p>
        <p>Psychology becomes even more thrilling and beneficial when paired with one of our other concentrations. The study of psychology will challenge you to think critically and communicate effectively as you engage in a variety of scientific methods that explore the cultural contexts of individual behavior.</p>
        <h3>Why Study Psychology?</h3>
        <p>Knowledge is powerful. By studying psychology, you understand why people think and act the way they do. This is an important skill that many businesses and organizations seek in their employees. As a social science, psychology requires you to hone the writing and research abilities essential to any job.</p>
        <h3>Find Your Dream Job</h3>
        <p>When you pair your degree with the internships, soft skills and career development training you'll get at SMC, you can major in almost any subject(s) you love and be ready for a great career in nearly any industry that interests you. Explore examples of careers available to students concentrating in Psychology:</p>
        <ul>
            <li>Clinical Psychologist</li>
            <li>Counselor</li>
            <li>Social Worker</li>
            <li>Human Resources Manager</li>
            <li>Marketing Manager</li>
            <li>Writer</li>
            <li>Parole Officer</li>
            <li>College Admissions Counselor</li>
        </ul>
        <div className="button-container">
            <h3>Want to learn more about SMC’s Psychology degree?</h3>
            <a href="https://www.smcsc.edu/academics/degrees-programs/major-areas-of-study/psychology/" target="_blank" rel="noopener noreferrer"><button className="btn btn-primary btn-block" >Get More Info</button></a>
        </div>
    </div>
);

export default Psychology;

import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

import * as ROUTES from "../../constants/routes";
import './Home.scss';
import {ReactComponent as HammondHall} from "../../images/home/hammond-hall.svg";
import {ReactComponent as TwoHikers} from "../../images/home/two-hikers.svg";
import {ReactComponent as WhiteMtnTop} from "../../images/white-mtn-top.svg";
import {ReactComponent as WhiteMtnBottom} from "../../images/white-mtn-bottom.svg";
import {ReactComponent as BlueStar} from "../../images/blue-star.svg";
import {ReactComponent as OrangeStar} from "../../images/orange-star.svg";
import { ReactComponent as TopoOrange } from "../../images/topographical-lines-orange.svg"
import CareerReadyDegrees from "../../images/home/career-ready-degrees.jpg";
import UserForm from '../UserForm';

class Home extends Component {
    render() {
        return (
            <main className="home" id="main" tabIndex="-1">
                <Helmet>
                    <title>Lookbook | Spartanburg Methodist College</title>
                    <meta name="description" content="Make Big Moves. By combining relevant classes with career-readiness and
                        support, we put you in a position to turn opportunities into accomplishments."/>
                    <meta name="keywords" content="associate degrees, bachelor's degrees, two year degrees, four year degrees,
                        Spartanburg Methodist College, Spartanburg, South Carolina, college, career development, Make Big Moves"/>
                    <link rel="canonical" href="https://makebigmoves.smcsc.edu"/>
                    <meta property="og:title" content="Lookbook | Spartanburg Methodist College"/>
                    <meta property="og:description" content="Make Big Moves. By combining relevant classes with career-readiness
                        and support, we put you in a position to turn opportunities into accomplishments."/>
                    <meta property="og:image" content="/og-image-home.png"/>
                    <meta property="og:url" content="https://makebigmoves.smcsc.edu"/>
                    <meta property="twitter:title" content="Lookbook | Spartanburg Methodist College"/>
                    <meta property="twitter:description" content="Make Big Moves. By combining relevant classes with career-readiness
                        and support, we put you in a position to turn opportunities into accomplishments."/>
                    <meta property="twitter:image" content="/og-image-home.png"/>
                    <meta name="twitter:card" content="summary_large_image"/>
                </Helmet>

                <div className="container">
                    <div className="fw-line-art p-1">
                        <HammondHall />
                    </div>
                </div>

                <div className="green-bar mb-8">
                    <h1>Make Big Moves</h1>
                </div>

                <div className="container text-center mb-8">
                    <h2>From Enrollment to Employment</h2>
                    <h3 className="mb-4">We’ll support you</h3>
                    <p>Spartanburg Methodist College is the smart choice if you want to Make Big Moves. Here, you’ll take the right classes and get the practical career experiences that make your goals achievable.</p>
                </div>

                <div className="purple-box mb-8">
                    <div className="purple-top">
                        <WhiteMtnTop />
                    </div>
                    <div className="purple-middle">
                        <div className="container white text-center">
                            <h2 className="mb-4">Earn Two Degrees in Four Years</h2>
                            <p className="mb-8">SMC students earn an associate degree at the halfway point to their bachelor’s degree! This additional credential broadens your opportunities for great jobs during college and if you have to postpone college for any reason before your finish your degree.</p>
                            <div className="paths mb-8">
                                <div>
                                    <div className="star"><BlueStar /></div>
                                    <div className="paths-text">
                                        <h4>2-year Associate Degrees</h4>
                                    </div>
                                </div>
                                <div>
                                    <div className="star"><OrangeStar /></div>
                                    <div className="paths-text">
                                        <h4>4-year Bachelor’s Degrees</h4>
                                    </div>
                                </div>
                            </div>
                            <NavLink to={ROUTES.OUR_DEGREES} className="btn btn-primary" aria-label="Learn about Academic Programs">See Our Programs</NavLink>
                        </div>
                    </div>
                    <div className="purple-bottom">
                        <WhiteMtnBottom />
                    </div>
                </div>

                <div className="container text-center mb-8">
                    <div className="offset-border-lime mb-8">
                        <img src={CareerReadyDegrees} alt="" />
                    </div>
                    <h2 className="mb-4">Career-Ready Degrees</h2>
                    <p>When employers come knocking, you’ll blow them away with your workplace knowledge and experience.</p>
                    <p className="mb-4">We’ll make sure of it.</p>
                    <NavLink to={ROUTES.CAREER_DEVELOPMENT} className="btn btn-primary" aria-label="Learn about Career Development">Find Out How</NavLink>
                </div>

                <div className="topo mb-8">
                    <TopoOrange />
                </div>

                <UserForm
                    key="user-form"
                    introText="SMC’s degree programs are flexible, adaptable and designed to give you career options."
                    user={this.props.user}
                    handleSubmit={this.props.handleSubmit}
                    handleInputChange={this.props.handleInputChange}
                    submitError={this.props.submitError}
                    submitted={this.props.userFormSubmitted}
                />

                <div className="container">
                    <div className="fw-line-art p-1">
                        <TwoHikers />
                    </div>
                </div>
            </main>
        );
    }
}

export default Home;

import React from 'react';
import ReligionImage from "../../../images/on-campus_degrees/religion.jpg";

const Religion = (props) => (
    <div>
        <div className="mb-8">
            <div className="offset-border-berry">
                <img src={ReligionImage} alt="Two students partnering in an anatomy lab" />
            </div>
        </div>
        <h2>Religion</h2>
        <p>The study of religion gives you an in-depth understanding of how societies view life and death, determine values and shape politics. A social-cultural system that includes behaviors and practices, religion reveals the connection between humans and supernatural elements.</p>
        <p>Religion, paired with one of our other concentrations in the SMC Bachelor of Arts program, will you give you a detailed understanding of history and culture, and provide you with a valuable perspective on world events and conflict.</p>
        <h3>Why Study Religion?</h3>
        <p>Studying religion can enhance your worldview and understanding of the similarities and differences among cultures and people. In addition, you’ll develop new insight and appreciation of your personal faith traditions while developing valuable critical thinking and communication skills.</p>
        <h3>Find Your Dream Job</h3>
        <p>When you pair your degree with the internships, soft skills and career development training you'll get at SMC, you can major in almost any subject(s) you love and be ready for a great career in nearly any industry that interests you. Explore examples of careers available to students concentrating in Religion:</p>
        <ul>
            <li>Chaplain</li>
            <li>Curator</li>
            <li>Lawyer</li>
            <li>Law Enforcement</li>
            <li>Minister</li>
            <li>Missionary</li>
            <li>Social Work</li>
            <li>Teacher</li>
            <li>Writer</li>
        </ul>
        <div className="button-container">
            <h3>Want to learn more about SMC’s Religion degree?</h3>
            <a href="https://www.smcsc.edu/academics/degrees-programs/major-areas-of-study/religion/" target="_blank" rel="noopener noreferrer"><button className="btn btn-primary btn-block" >Get More Info</button></a>        </div>
    </div>
);

export default Religion

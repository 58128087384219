import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

import './Academics.scss';
import * as ROUTES from "../../constants/routes";
import HeroImage from "../../images/academics/hero.jpg"
import ClassImg from "../../images/academics/class.jpg"
import NoahMooreImg from "../../images/testimonials/noah-moore-headshot-lime-outline.png"
import {ReactComponent as BlueStar} from "../../images/blue-star.svg";
import {ReactComponent as OrangeStar} from "../../images/orange-star.svg";
import { ReactComponent as TopoLime } from "../../images/topographical-lines-lime.svg"
import { ReactComponent as TopoBlue } from "../../images/topographical-lines-blue.svg"

class Academics extends Component {
    render() {
        return (
            <main className="academics" id="main" tabIndex="-1">
                <Helmet>
                    <title>Academics | SMC Lookbook</title>
                    <meta name="description" content="At SMC we generate your success by merging knowledge with action. The
                        result: an enhanced ability to solve complex, real-world issues." />
                    <meta name="keywords" content="2-year, two year, associate degrees, arts, business, criminal justice, fine
                        arts, religious studies, science, 4-year, four year, bachelor's degrees, business, english, history,
                        religion" />
                    <link rel="canonical" href="https://makebigmoves.smcsc.edu/academics/that-work" />
                    <meta property="og:title" content="Academics | SMC Lookbook" />
                    <meta property="og:description" content="At SMC we generate your success by merging knowledge with action.
                        The result: an enhanced ability to solve complex, real-world issues." />
                    <meta property="og:image" content="/og-image-home.png" />
                    <meta property="og:url" content="https://makebigmoves.smcsc.edu/academics/that-work" />
                    <meta property="twitter:title" content="Academics | SMC Lookbook" />
                    <meta property="twitter:description" content="At SMC we generate your success by merging knowledge with
                        action. The result: an enhanced ability to solve complex, real-world issues." />
                    <meta property="twitter:image" content="/og-image-home.png" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>

                <div className="photo-hero-container mb-8">
                    <img src={HeroImage} alt="College students attending a graduation ceremony" />
                    <div className="hero-topo">
                        <TopoLime />
                    </div>
                </div>

                <div className="container text-center mb-8">
                    <div className="mb-4">
                        <h2>Make Smart Moves</h2>
                        <h3>Academics That Work</h3>
                    </div>
                    <p>Mastering subject matter is important. But what’s the point of learning if you’re not doing? At SMC we generate your success by merging knowledge with action. The result: an enhanced ability to solve complex, real-world issues.</p>
                </div>

                <div className="container text-center">
                    <div className="paths mb-8">
                        <div>
                            <div className="star"><BlueStar /></div>
                            <div className="paths-text">
                                <h4>Personal</h4>
                                <ul>
                                    <li>Ongoing Academic Advising</li>
                                    <li>One-on-One Career Coaching</li>
                                    <li>Personalized Goal Planning</li>
                                    <li>Interest-based Coursework</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="star"><OrangeStar /></div>
                            <div className="paths-text">
                                <h4>Powerful</h4>
                                <ul>
                                    <li>“Learn to Do” Environment</li>
                                    <li>Career-focused Curriculum</li>
                                    <li>Authentic Workplace Experiences</li>
                                    <li>Discussion-based Learning</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container text-center mb-8">
                    <div className="mb-4">
                        <h2>Part Guides. Part Mentors.</h2>
                        <h3>Our Professors Are All In</h3>
                    </div>
                    <p>SMC instructors serve a common goal: your success. It’s their job to connect classroom learning to real world living. And they do it before class, after class, in the evenings and on the weekends.</p>
                </div>

                <div className="part-guides-container mb-8">
                    <div className="part-guides-left">
                        <div className="offset-border-lime">
                            <img src={ClassImg} alt="Students and instructor in class" />
                        </div>
                    </div>
                    <div className="part-guides-right">
                        <div>
                            <p className="part-guides-quote">“While listening is important, I also want students to be curious, ask questions and even teach their peers through the activities in class. Once class is over, I make it a general practice to keep my office door open so that students can continue the conversation, ask for help or simply talk about life, because all of life is a classroom.”</p>
                            <p>&mdash;<strong>Dr. Kirk Hansen</strong>, Professor of History</p>
                        </div>
                    </div>
                </div>

                <div className="topo mb-8">
                    <TopoBlue />
                </div>

                <div className="container text-center mb-8">
                    <div className="mb-4">
                        <h2>Real World Application</h2>
                    </div>
                    <p>On top of the experience you’ll gain in the classroom, SMC recognizes the value of applying your education to the real world. Obtaining an internship is also required as part of the Camak Core and encourages students to explore the career paths their concentrations can lead to.</p>
                </div>

                <div className="container text-center mb-8">
                    <div className="youtube-embed">
                        <iframe src="https://www.youtube.com/embed/3yXIRv8-d0k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>

                <div className="testimonial bg-berry white mb-8">
                    <img src={NoahMooreImg} alt="Noah Moore" />
                    <div>
                        <p className="testimonial-name">Noah Moore</p>
                        <p className="testimonial-class mb-4">SMC Class 2021</p>
                        <p className="testimonial-text mb-4">Noah joined the Cornbread Consulting Firm as an intern over the summer using the skills he gained from his business and history concentrations. Through this experience, he saw the importance of operations and management in the restaurant industry first hand.</p>
                        <p className="testimonial-quote">“I really enjoyed getting to meet local business owners and seeing how each of them operates their own business.”</p>
                    </div>
                </div>

                <div className="container text-center mb-4">
                    <div className="mb-4">
                        <h2>Ready for anything</h2>
                    </div>
                    <p>When employers come knocking, you’ll blow them away with your workplace knowledge and experience.</p>
                    <p>We’ll make sure of it.</p>
                    <NavLink to={ROUTES.CAREER_DEVELOPMENT} className="btn btn-primary" aria-label="Learn about Career Development">Find Out How</NavLink>
                </div>
            </main>
        );
    }
}

export default Academics;

import React, { Component } from "react";
import "./NoMatch.scss";
import { Helmet } from "react-helmet";

class NoMatch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            purlID: window.location.pathname.replace("/", "")
        };
    }

    componentDidMount() {
        //const userFound = this.props.getUserDetails(this.state.purlID);
        const userFound = false;

        if (userFound) {
            this.props.history.push(`/`);
        }
    }

    render() {
        return (
            <div className="four-o-four">
                <Helmet>
                    <title>Oops! Page not found</title>
                </Helmet>
                <h1>404</h1>
            </div>
        );
    }
}

export default NoMatch;

import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import './FinancialAid.scss';
import HeroImage from "../../images/financial_aid/hero.jpg"
import { ReactComponent as GreenMtn } from '../../images/green-mtn.svg';
import { ReactComponent as Support } from '../../images/financial_aid/support.svg';
import { ReactComponent as TopoLime } from "../../images/topographical-lines-lime.svg"

import ScholarshipCalculator from '../ScholarshipCalculator';

class FinancialAid extends Component {
    render() {
        return (
            <div className="financial-aid">
                <Helmet>
                    <title>Financial Aid | SMC Lookbook</title>
                    <meta name="description" content="Big moves don’t have to break the bank. We work hard to keep our tuition
                        low and stable, so you can aim high and explore." />
                    <meta name="keywords" content="Scholarship Calculator, Federal Grants, State Grants and Scholarships, SMC
                        Merit Scholarship, United Methodists, Church Scholarship, Outside Scholarships, Work Study, LIFE Scholarship
                        , FAFSA" />
                    <link rel="canonical" href="https://makebigmoves.smcsc.edu/financial-aid" />
                    <meta property="og:title" content="Financial Aid | SMC Lookbook" />
                    <meta property="og:description" content="Big moves don’t have to break the bank. We work hard to keep our
                        tuition low and stable, so you can aim high and explore." />
                    <meta property="og:image" content="/og-image-home.png" />
                    <meta property="og:url" content="https://makebigmoves.smcsc.edu/financial-aid" />
                    <meta property="twitter:title" content="Financial Aid | SMC Lookbook" />
                    <meta property="twitter:description" content="Big moves don’t have to break the bank. We work hard to keep
                        our tuition low and stable, so you can aim high and explore." />
                    <meta property="twitter:image" content="/og-image-home.png" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>

                <div className="photo-hero-container mb-8">
                    <img src={HeroImage} alt="College students studying on couches" />
                    <div className="hero-topo">
                        <TopoLime />
                    </div>
                </div>

                <div className="container text-center mb-4">
                    <div className="mb-4">
                        <h2>The Low Cost</h2>
                        <h3>Of Awesome</h3>
                    </div>
                    <p>Big moves don’t have to break the bank. After graduation you should be tackling your career, not a mountain of debt. We work hard to keep our tuition low and stable, so you can aim high and explore.</p>
                </div>

                <div className="calculator-container mb-8">
                    <GreenMtn />
                    <ScholarshipCalculator
                        key="calculator"
                        user={this.props.user}
                        handleInputChange={this.props.handleInputChange}
                        handleSubmit={this.props.handleSubmit}
                    />
                </div>

                <div className="container text-center">
                    <h2>You have our support</h2>
                    <p>Our financial aid team will work with you—even help you fill out the FAFSA—to help you get the most aid available to you.</p>
                </div>

                <div className="container">
                    <div className="fw-line-art p-1">
                        <Support />
                    </div>
                </div>
            </div>
        );
    }

}

export default FinancialAid;

import React from 'react';
import BaBusinessImage from "../../../images/online_degrees/baba-marketing.jpg";

const MarketingAndSales = (props) => (
    <div>
        <div className="mb-8">
            <div className="offset-border-berry">
                <img src={BaBusinessImage} alt="A student at her computer" />
            </div>
        </div>
        <h2>Bachelor of Arts in Business Administration – Marketing and Sales</h2>
        <p>A Business Administration degree in Marketing and Sales sets up students to play a key role in growing a business through promotion, advertising, and sales. You will learn the key principles behind successful marketing, sales techniques and strategies, and overall knowledge of business operations and finance to spark your career.</p>
        <p>Combined with the professional development and soft skills learned through SMC’s unique Camak Core program, graduates will be ready to step right into a new career and contribute immediately.</p>
        <h3>Why Get a Marketing and Sales Degree?</h3>
        <p>Business degrees are in high demand in today’s world. Marketing and Sales degrees are among the most popular for students and are highly sought by businesses. You’ll learn the strategies and tactics in the art of promoting a business and converting sales of a company’s products and services.</p>
        <p>Since our program is flexible and affordable, you can gain the tools you need to enhance your career without interrupting it.</p>
        <h3>Find Your Dream Job</h3>
        <p>Career opportunities, on a national level, in business and financial operations are projected to grow faster than average for all occupations. Explore examples of careers available to students with a B.A. focused on Marketing and Sales:</p>
        <ul>
            <li>Marketing Manager</li>
            <li>Sales Manager</li>
            <li>Digital Marketing Professional</li>
            <li>Regional or District Sales</li>
            <li>Event Marketer</li>
            <li>Advertising Representative</li>
        </ul>
        <div className="button-container">
            <h3>Want to learn more about SMC’s Online Marketing and Sales degree?</h3>
            <a href="https://www.smcsc.edu/academics/spartanburg-methodist-college-online-degree-programs/online-bachelor-of-arts-in-business-administration-marketing-and-sales-concentration/" target="_blank" rel="noopener noreferrer"><button className="btn btn-primary btn-block">Get More Info</button></a>
        </div>
    </div>
);

export default MarketingAndSales;

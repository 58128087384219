import React from 'react';
import { NavLink } from "react-router-dom";
import * as ROUTES from '../../constants/routes';

const CalculatorResults = (props) => (
    <div className="results-container">
        {props.results.total ?
            <div>
                <p className="result">Based on what you shared, you may be eligible for up to <strong>${props.results.total}</strong> in financial aid.</p>
                <p className="small"><strong>Scholarship Breakdown</strong></p>
                <ul className="scholarships">
                    {props.results.smc &&
                        <li><p className="result">SMC Scholarship: ${props.results.smc}</p></li>
                    }
                    {props.results.state &&
                        <li><p className="result">{props.results.state.name}: ${props.results.state.amount}</p></li>
                    }
                </ul>
            </div>
            :
            <p className="result">Thank you for submitting! More information on financial aid opportunities is available on the <a href="https://www.smcsc.edu/admissions/financial-aid/grants-and-scholarships/" target="_blank" rel="noopener noreferrer" className="cyan-link">SMC Grants and Scholarships page.</a></p>
        }
        <ul className="next-links">
            <li>
                <a href={ROUTES.APPLY} target="_blank" rel="noopener noreferrer">Apply</a>
            </li>
            <li>
                <a href={ROUTES.VISIT} target="_blank" rel="noopener noreferrer">Visit Campus</a>
            </li>
            <li>
                <NavLink to={ROUTES.ACADEMICS}>Programs</NavLink>
            </li>
        </ul>
        <p>
            {props.results.state ? 'Dependent upon need, you may also qualify for the South Carolina Tuition Grant worth up to $3,500. ' : ''}These scholarships are for first-time freshman students. Find information on other types of scholarships on
            our <a href={ROUTES.WEBSITE} target="_blank" rel="noopener noreferrer" className="cyan-link">website</a>.
        </p>
        <p className="text-center">
            <button className="btn btn-secondary" onClick={props.handleReset}>Start Over</button>
        </p>
    </div>
);

export default CalculatorResults;

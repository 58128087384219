import React, { Component } from "react";
import "./Form.scss";
//import { monthOptions, dayOptions } from "../../constants/dateOptions";

class UserFormInputs extends Component {
    constructor(props) {
        super(props);

        UserFormInputs.createYears = UserFormInputs.createYears.bind(this);
    }

    static createYears() {
        let years = [];
        for (let i = 2019; i > 1919; i--) {
            years.push(
                <option key={i} value={i.toString()}>
                    {i}
                </option>
            );
        }
        return years;
    }

    render() {
        let {
            user,
            formClass,
            handleInputChange,
            handleUserForm,
            buttonText,
            error
        } = this.props;

        let onCampusProgramOptions = [
            "Bachelor of Arts - Business",
            "Bachelor of Arts - Criminal Justice",
            "Bachelor of Arts - English",
            "Bachelor of Arts - History",
            "Bachelor of Arts - Psychology",
            "Bachelor of Arts - Religion",
            "Bachelor of Arts in Business Administration - Accounting and Finance",
            "Bachelor of Arts in Business Administration - Management and Entrepreneurship",
            "Bachelor of Arts in Business Administration - Marketing and Sales",
        ]

        let onlineProgramOptions = [
            "Associate in Arts Degree",
            "Associate in Business Degree",
            "Associate in Criminal Justice Degree",
            "Bachelor of Arts in Business Administration – Accounting and Finance",
            "Bachelor of Arts in Business Administration – Management and Entrepreneurship",
            "Bachelor of Arts in Business Administration – Marketing and Sales",
            "Bachelor of Arts - Business",
            "Bachelor of Arts - Criminal Justice",
            "Bachelor of Arts - Psychology",
            "Information Technology Support Specialist - Certificate"
        ]

        let onCampusStudentTypes = [
            { value:"first-year", label: "First-year Student" },
            { value:"transfer", label: "Transfer Student" }
        ]

        let onlineStudentTypes = [
            { value: "no college experience", label: "I have no previous college experience" },
            { value: "previous college experience", label: "I have previous college experience" },
            { value: "currently enrolled", label: "I am currently enrolled in another college" }
        ]

        return (
            <form
                className={error ? formClass + " error" : ""}
                onSubmit={handleUserForm}
            >
                <div className="input-group input-fade-in">
                    <label htmlFor="programOfInterest">
                        Are you interested in an on campus or online experience?
                    </label>
                    <div className="select-group">
                        <select
                            name="programType"
                            id="programType"
                            value={user.programType}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Select an experience</option>
                            <option value="On campus">On Campus</option>
                            <option value="Online">
                                Online
                            </option>
                        </select>
                    </div>
                </div>
                <div className="input-group input-fade-in">
                    <label htmlFor="programOfInterest">
                        Choose a program of interest
                    </label>
                    <div className="select-group">
                        <select
                            name="programOfInterest"
                            id="programOfInterest"
                            value={user.programOfInterest}
                            onChange={handleInputChange}
                        >
                            <option value="">I'm exploring my options</option>
                            {
                                user.programType === "On campus" &&
                                onCampusProgramOptions.map(
                                    (option, idx) => {
                                        return <option value={option} key={idx}>{option}</option>
                                    }
                                )
                            }
                            {
                                user.programType === "Online" &&
                                onlineProgramOptions.map(
                                    (option, idx) => {
                                        return <option value={option} key={idx}>{option}</option>
                                    }
                                )
                            }
                        </select>
                    </div>
                </div>
                <div className="input-group input-fade-in">
                    <label htmlFor="studentType">{user.programType === "Online" ? "College Experience" : "I am a ..."}</label>
                    <div className="select-group">
                        <select
                            name="studentType"
                            id="studentType"
                            required
                            value={user.studentType}
                            onChange={handleInputChange}
                        >
                            {
                                user.programType === "On campus" &&
                                onCampusStudentTypes.map(
                                    (option, idx) => {
                                        return <option value={option.value} key={idx}>{option.label}</option>
                                    }
                                )
                            }
                            {
                                user.programType === "Online" &&
                                onlineStudentTypes.map(
                                    (option, idx) => {
                                        return <option value={option.value} key={idx}>{option.label}</option>
                                    }
                                )
                            }
                        </select>
                    </div>
                </div>
                <div>
                {user.programType === "On campus" &&
                <div className="input-group">
                    <label htmlFor="expectedEntryTerm">
                        Expected Entry Term
                    </label>
                    <div className="select-group">
                        <select
                            name="expectedEntryTerm"
                            id="expectedEntryTerm"
                            required
                            value={user.expectedEntryTerm}
                            onChange={handleInputChange}
                        >
                            <option value="">Select a term</option>
                            <option value="Spring 2021">Spring 2021</option>
                            <option value="Fall 2021">Fall 2021</option>
                            <option value="Spring 2022">Spring 2022</option>
                            <option value="Fall 2022">Fall 2022</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                </div>
                }
                </div>
                <div className="input-group input-fade-in">
                    <label htmlFor="firstName">First name</label>
                    <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        required
                        defaultValue={user.firstName}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="input-group input-fade-in">
                    <label htmlFor="lastName">Last name</label>
                    <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        required
                        defaultValue={user.lastName}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="input-group input-fade-in">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        required
                        defaultValue={user.email}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="input-group input-fade-in">
                    <label htmlFor="mobilePhone">Mobile Phone</label>
                    <input
                        type="tel"
                        name="mobilePhone"
                        id="mobilePhone"
                        required
                        defaultValue={user.mobilePhone}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                {
                    user.mobilePhone &&
                        <div className="input-group">
                            <label><input type="checkbox" name="smsNotifications" id="smsNotifications" onChange={handleInputChange} />Yes I would like to receive text messages from Spartanburg Methodist College. We use text to send reminders specific to deadlines and missing items. Standard message and data rates apply.</label>
                            <p></p>
                        </div>
                }
                </div>
                {/*
                <div className="input-group input-fade-in date-group">
                    <label>Birthdate</label>
                    <div className="select-group">
                        <select
                            name="birthMonth"
                            id="birthMonth"
                            aria-label="birth month"
                            required
                            value={user.birthMonth}
                            onChange={handleInputChange}
                        >
                            <option value="">Month</option>
                            {monthOptions.map((option, idx) => (
                                <option key={idx} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="select-group">
                        <select
                            name="birthDay"
                            id="birthDay"
                            aria-label="birth day"
                            required
                            value={user.birthDay}
                            onChange={handleInputChange}
                        >
                            <option value="">Day</option>
                            {dayOptions.map((option, idx) => (
                                <option key={idx} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="select-group">
                        <select
                            name="birthYear"
                            id="birthYear"
                            aria-label="birth year"
                            required
                            value={user.birthYear}
                            onChange={handleInputChange}
                        >
                            <option value="">Year</option>
                            {UserFormInputs.createYears()}
                        </select>
                    </div>
                </div>
                */}
                <button
                    id="userForm"
                    className="btn btn-primary btn-block"
                    type="submit"
                >
                    {buttonText}
                </button>
            </form>
        );
    }
}

export default UserFormInputs;

import React from 'react';
import BusinessImage from "../../../images/online_degrees/ba-business.jpg";

const BABusiness = () => (
    <div>
        <div className="mb-8">
            <div className="offset-border-berry">
                <img src={BusinessImage} alt="A student sitting with his laptop" />
            </div>
        </div>

        <h2>Bachelor of Arts - Business</h2>
        <p>The Bachelor of Arts - Business is a versatile online degree for students who are interested in advancing in or entering into the business world. This is an excellent credential for a variety of career paths.</p>
        <p>For even more versatility, pair your business concentration with a concentration in either psychology or criminal justice. These combinations make your SMC bachelor’s degree even more relevant and valuable to employers in a wider range of career paths.</p>

        <h3>Why Get a Bachelor’s Degree in Business?</h3>
        <p>A business degree provides you a well-rounded education in all aspects of the business world and how businesses function. Whether you’re looking to enter the workforce or bolster your current career, a business degree gives you the tools to do so.</p>
        <p>Since our program is flexible and affordable, you can gain the tools you need to enhance your career without interrupting it.</p>

        <h3>Find Your Dream Job</h3>
        <p>Business degree graduates are in high demand, and there are several career paths you can choose, including::</p>
        <ul>
            <li>Sales</li>
            <li>Marketing</li>
            <li>Consumer Analytics</li>
            <li>Human Resources</li>
            <li>Business Administration</li>
        </ul>

        <div className="button-container">
            <h3>Want to learn more about SMC’s Online Bachelor of Arts - Business Degree?</h3>
            <a href="https://www.smcsc.edu/academics/spartanburg-methodist-college-online-degree-programs/online-bachelor-of-arts-in-criminal-justice-business-concentration/" target="_blank" rel="noopener noreferrer"><button className="btn btn-primary btn-block" >Get More Info</button></a>
        </div>
    </div>
);

export default BABusiness;

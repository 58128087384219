import React from 'react';
import CriminalJusticeImage from "../../../images/on-campus_degrees/criminal-justice.jpg";

const CriminalJustice = () => (
    <div>
        <div className="mb-8">
            <div className="offset-border-berry">
                <img src={CriminalJusticeImage} alt="Police officer lecturing a classroom" />
            </div>
        </div>
        <h2>Criminal Justice</h2>
        <p>Centered around critical thinking skills, careers in criminal justice are as rewarding as they are varied. Working in a criminal justice-related job requires you to solve complex problems while advocating justice for both victims and criminals.</p>
        <p>A criminal justice concentration is the ideal path for those interested in making an impact on the lives of others. Paired with one of our other six concentrations, the study of criminal justice can prepare you for law school or a variety of law enforcement training opportunities.</p>
        <h3>Why Study Criminal Justice?</h3>
        <p>Beyond the development of essential critical thinking and problem-solving skills, you’ll learn to think quickly and communicate clearly. In addition, studying criminal justice positions you for a stable career in a high-growth field.</p>
        <h3>Find Your Dream Job</h3>
        <p>When you pair your degree with the internships, soft skills and career development training you'll get at SMC, you can major in almost any subject(s) you love and be ready for a great career in nearly any industry that interests you. Explore examples of careers available to students concentrating in Criminal Justice:</p>
        <ul>
            <li>Lawyer</li>
            <li>Paralegal</li>
            <li>Victim’s Advocate</li>
            <li>Forensic Psychologist</li>
            <li>Probation Officer</li>
            <li>Police Officer</li>
        </ul>
        <div className="button-container">
            <h3>Want to learn more about SMC’s Criminal Justice degree?</h3>
            <a href="https://www.smcsc.edu/academics/degrees-programs/major-areas-of-study/criminal-justice/" target="_blank" rel="noopener noreferrer"><button className="btn btn-primary btn-block" >Get More Info</button></a>
        </div>
    </div>
);

export default CriminalJustice;

import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';

import { ReactComponent as BlueMtn } from '../../images/blue-mtn.svg';
import { ReactComponent as OrangeMtn } from '../../images/orange-mtn.svg';

class Concentrations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentConcentration: 0,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(e) {
        this.setState({ currentConcentration: Number(e.target.value) });
    }

    render() {
        return (
            <div className={'concentration-container ' + this.props.kind}>
                {this.props.kind === 'on-campus' &&
                <OrangeMtn />
                }
                {this.props.kind === 'online' &&
                <BlueMtn />
                }
                <div className="concentration">
                    <div className="concentration-wrapper">
                        <div className="concentration-intro">
                            { this.props.concentrationIntro }
                        </div>
                        <form className="mb-8">
                            <div className="input-group">
                                <label htmlFor="concentrations">
                                    {this.props.dropdownLabel}
                                </label>
                                <div className="select-group">
                                    <select
                                        name="concentrations"
                                        id="concentrations"
                                        required
                                        value={this.state.currentConcentration}
                                        onChange={this.handleInputChange}
                                    >
                                        {this.props.concentrations.map((con, idx) => {
                                            return <option key={idx} value={idx}>{con.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </form>
                        <div className="concentration-info">
                            {this.props.concentrations.map((con, idx) => {
                                return (
                                <CSSTransition
                                    in={this.state.currentConcentration === idx}
                                    timeout={500}
                                    classNames="display"
                                    unmountOnExit
                                    appear
                                    key={idx}
                                >
                                    {con.component}
                                </CSSTransition>)
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Concentrations;

import React from 'react';
import { NavLink } from "react-router-dom";

import './Footer.scss';
import * as ROUTES from '../../constants/routes';

import { ReactComponent as Logo } from '../../images/logo.svg';
import { ReactComponent as FooterTop  } from '../../images/footer-top.svg';

const Footer = () => (
    <footer>
        <div className="footer-top">
            <FooterTop />
        </div>
        <div className="footer-bottom">
            <div className="footer-logo-container">
                <Logo className="footer-logo" />
            </div>
            <div className="footer-container">
                <div className="footer-address mb-8">
                    <address>
                        <p><strong>Spartanburg Methodist College</strong><br />
                            <em>Office of Admissions</em></p>
                        <p>1000 Powell Mill Road<br />
                            Spartanburg, SC 29301</p>
                        <p><a href="mailto:admiss@smcsc.edu">admiss@smcsc.edu</a><br />
                            <a href="tel:+18645874213">+1 (864) 587-4213</a></p>
                    </address>
                </div>
                <nav className="footer-links">
                    <div>
                        <span><NavLink to={ROUTES.HOME}>Make Big Moves</NavLink></span>
                        <span><NavLink to={ROUTES.OUR_DEGREES}>Our On-Campus Degrees</NavLink></span>
                        <span><NavLink to={ROUTES.ONLINE_DEGREES}>Our Online Degrees</NavLink></span>
                    </div>
                    <div>
                        <span><NavLink to={ROUTES.ACADEMICS}>Academics That Work</NavLink></span>
                        <span><NavLink to={ROUTES.CAREER_DEVELOPMENT}>Career Development</NavLink></span>
                        <span><NavLink to={ROUTES.CAMPUS}>Campus Living</NavLink></span>
                    </div>
                    <div>
                        <span><NavLink to={ROUTES.SPARTANBURG}>City of Spartanburg</NavLink></span>
                        <span><NavLink to={ROUTES.FINANCIAL_AID}>Financial Aid</NavLink></span>
                        <span><a href={ROUTES.VISIT} target="_blank" rel="noopener noreferrer">Visit</a></span>
                        <span><a href={ROUTES.APPLY} target="_blank" rel="noopener noreferrer">Apply</a></span>
                    </div>
                </nav>
            </div>
        </div>
    </footer>
);

export default Footer;

import React, { Component } from 'react';
import './Navigation.scss';
import { NavLink } from "react-router-dom";
import * as ROUTES from '../../constants/routes';
import Logo from '../../images/logo.svg';

class Navigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showNavigation: false,
            showLinks: {
                academics: false,
                campus: false,
            }
        };

        this.toggleNavigation = this.toggleNavigation.bind(this);
        this.toggleDropDown = this.toggleDropDown.bind(this);
        this.handleSubNavClick = this.handleSubNavClick.bind(this);
    }

    toggleNavigation(e) {
        e.preventDefault();
        this.setState(prevState => ({
            showNavigation: !prevState.showNavigation,
        }));
    }

    toggleDropDown(e) {
        e.preventDefault();
        let menu = e.target.dataset.target;
        this.setState(prevState => ({
            showLinks: {
                ...prevState.showLinks,
                [menu]: !prevState.showLinks[menu]
            }
        }));
    }

    handleSubNavClick() {
        this.setState({
            showNavigation: false,
            showLinks: {
                academics: false,
                campus: false,
            }
        })
    }

    render() {
        return (
            <header>
                <a href="#main" className="sr-skip btn btn-primary">Skip Navigation</a>
                <NavLink to={ROUTES.HOME} onClick={this.handleSubNavClick}>
                    <img src={Logo} className="smc-logo" alt=""/>
                </NavLink>
                <nav className={`navbar ${this.state.showNavigation ? 'active' : ''}`}>
                    <button className="nav-toggle" onClick={this.toggleNavigation}>
                        Menu
                        <div className="nav-toggle-icon">
                            <div className="nav-toggle-bar">&nbsp;</div>
                        </div>
                    </button>
                    {this.state.showNavigation &&
                        <ul className="nav-mobile">
                            <li><NavLink to={ROUTES.HOME} onClick={this.handleSubNavClick}>Home</NavLink></li>
                            <li>
                                <NavLink
                                    aria-expanded={this.state.showLinks.academics ? 'true' : 'false'}
                                    aria-haspopup="true"
                                    aria-label={this.state.showLinks.academics ?
                                        'Academics menu open'
                                        :
                                        'Academics menu closed'}
                                    aria-live="assertive"
                                    className={this.state.showLinks.academics ? 'open' : ''}
                                    data-target="academics"
                                    onClick={this.toggleDropDown}
                                    to={"#"}
                                >
                                    Academics
                            </NavLink>
                                {this.state.showLinks.academics ?
                                    <ul className="dropdown" id="academics" role="listbox" tabIndex="-1">
                                        <li><NavLink
                                            to={ROUTES.OUR_DEGREES}
                                            onClick={this.handleSubNavClick}
                                        >On-Campus Degrees</NavLink></li>
                                        <li><NavLink
                                            to={ROUTES.ONLINE_DEGREES}
                                            onClick={this.handleSubNavClick}
                                        >Online Degrees</NavLink></li>
                                        <li><NavLink
                                            to={ROUTES.ACADEMICS}
                                            onClick={this.handleSubNavClick}
                                        >Academics That Work</NavLink></li>
                                        <li><NavLink
                                            to={ROUTES.CAREER_DEVELOPMENT}
                                            onClick={this.handleSubNavClick}
                                        >Career Development</NavLink></li>
                                    </ul>
                                    :
                                    null
                                }
                            </li>
                            <li>
                                <NavLink
                                    aria-expanded={this.state.showLinks.campus ? 'true' : 'false'}
                                    aria-haspopup="true"
                                    aria-label={this.state.showLinks.campus ?
                                        'Campus menu open'
                                        :
                                        'Campus menu closed'}
                                    aria-live="assertive"
                                    className={this.state.showLinks.campus ? 'open' : ''}
                                    data-target="campus"
                                    onClick={this.toggleDropDown}
                                    to={"#"}
                                >Campus</NavLink>
                                {this.state.showLinks.campus ?
                                    <ul className="dropdown" id="campus" role="listbox" tabIndex="-1">
                                        <li><NavLink to={ROUTES.CAMPUS} onClick={this.handleSubNavClick}>
                                            Campus Living
                                    </NavLink></li>
                                        <li><NavLink to={ROUTES.SPARTANBURG} onClick={this.handleSubNavClick}>
                                            Spartanburg Living
                                    </NavLink></li>
                                    </ul>
                                    :
                                    null
                                }
                            </li>
                            <li><NavLink to={ROUTES.FINANCIAL_AID} onClick={this.handleSubNavClick}>
                                Financial Aid
                            </NavLink>
                            </li>
                            <li>
                                <a
                                    href={ROUTES.VISIT}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={this.handleSubNavClick}
                                >
                                    Visit
                            </a>
                            </li>
                            <li>
                                <a
                                    href={ROUTES.APPLY}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={this.handleSubNavClick}
                                >
                                    Apply
                            </a>
                            </li>
                        </ul>
                    }
                    <ul className="nav-desktop">
                        <li><NavLink to={ROUTES.HOME} onClick={this.handleSubNavClick}>Home</NavLink></li>
                        <li>
                            <NavLink
                                aria-expanded={this.state.showLinks.academics ? 'true' : 'false'}
                                aria-haspopup="true"
                                aria-label={this.state.showLinks.academics ?
                                    'Academics menu open'
                                    :
                                    'Academics menu closed'}
                                aria-live="assertive"
                                className={this.state.showLinks.academics ? 'open' : ''}
                                data-target="academics"
                                onClick={this.toggleDropDown}
                                to={"#"}
                            >
                                Academics
                        </NavLink>
                            {this.state.showLinks.academics ?
                                <ul className="dropdown" id="academics" role="listbox" tabIndex="-1">
                                    <li><NavLink
                                        to={ROUTES.OUR_DEGREES}
                                        onClick={this.handleSubNavClick}
                                    >On-Campus Degrees</NavLink></li>
                                    <li><NavLink
                                        to={ROUTES.ONLINE_DEGREES}
                                        onClick={this.handleSubNavClick}
                                    >Online Degrees</NavLink></li>
                                    <li><NavLink
                                        to={ROUTES.ACADEMICS}
                                        onClick={this.handleSubNavClick}
                                    >Academics That Work</NavLink></li>
                                    <li><NavLink
                                        to={ROUTES.CAREER_DEVELOPMENT}
                                        onClick={this.handleSubNavClick}
                                    >Career Development</NavLink></li>
                                </ul>
                                :
                                null
                            }
                        </li>
                        <li>
                            <NavLink
                                aria-expanded={this.state.showLinks.campus ? 'true' : 'false'}
                                aria-haspopup="true"
                                aria-label={this.state.showLinks.campus ?
                                    'Campus menu open'
                                    :
                                    'Campus menu closed'}
                                aria-live="assertive"
                                className={this.state.showLinks.campus ? 'open' : ''}
                                data-target="campus"
                                onClick={this.toggleDropDown}
                                to={"#"}
                            >Campus</NavLink>
                            {this.state.showLinks.campus ?
                                <ul className="dropdown" id="campus" role="listbox" tabIndex="-1">
                                    <li><NavLink to={ROUTES.CAMPUS} onClick={this.handleSubNavClick}>
                                        Campus Living
                                </NavLink></li>
                                    <li><NavLink to={ROUTES.SPARTANBURG} onClick={this.handleSubNavClick}>
                                        Spartanburg Living
                                </NavLink></li>
                                </ul>
                                :
                                null
                            }
                        </li>
                        <li><NavLink to={ROUTES.FINANCIAL_AID} onClick={this.handleSubNavClick}>
                            Financial Aid
                        </NavLink>
                        </li>
                        <li>
                            <a
                                href={ROUTES.VISIT}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={this.handleSubNavClick}
                            >
                                Visit
                        </a>
                        </li>
                        <li>
                            <a
                                href={ROUTES.APPLY}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={this.handleSubNavClick}
                            >
                                Apply
                        </a>
                        </li>
                    </ul>
                </nav>
            </header>
        );
    }
}

export default Navigation;

import React from 'react';
import BaCrimImage from "../../../images/online_degrees/ba-criminal_justice.jpg"

const BACriminalJustice = () => (
    <div>
        <div className="mb-8">
            <div className="offset-border-berry">
                <img src={BaCrimImage} alt="Woman standing in front of the sheriff offices seal" />
            </div>
        </div>

        <h2>Bachelor of Arts - Criminal Justice</h2>
        <p>The Bachelor of Arts - Criminal Justice is a versatile online degree for students who are interested in advancing or beginning their career in criminal justice. From public safety to courts to jails, there are a number of career fields you can pursue.</p>
        <p>For even more versatility, pair your criminal justice concentration with a concentration in either psychology or business. These combinations make your SMC bachelor’s degree even more relevant and valuable to employers in a wider range of career paths.</p>

        <h3>Why Get a Bachelor’s Degree in Criminal Justice?</h3>
        <p>A criminal justice degree prepares you to serve your community. You’ll learn key investigative skills and the operation of key institutions like courts, jails, and probation offices.</p>
        <p>Since our program is flexible and affordable, you can gain the tools you need to enhance your career without interrupting it.</p>

        <h3>Find Your Dream Job</h3>
        <p>Criminal justice degree graduates will gain the knowledge and skills to pursue several career options following graduation, including:</p>
        <ul>
            <li>Police Officer</li>
            <li>Investigator</li>
            <li>Forensic Scientist</li>
            <li>Social Worker</li>
            <li>Law School Student</li>
        </ul>

        <div className="button-container">
            <h3>Want to learn more about SMC’s Online Bachelor of Arts - Criminal Justice Degree?</h3>
            <a href="https://www.smcsc.edu/academics/spartanburg-methodist-college-online-degree-programs/online-bachelor-of-arts-in-criminal-justice-business-concentration/" target="_blank" rel="noopener noreferrer"><button className="btn btn-primary btn-block" >Get More Info</button></a>
        </div>
    </div>
);

export default BACriminalJustice;

import React, { Component } from 'react';

import './ScholarshipCalculator.scss';
import CalculatorScoresForm from "./ScoresForm";
import UserFormInputs from "../UserForm/Form";
import CalculatorResults from "./Results";

class ScholarshipCalculator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showScoresForm: true,
            showUserForm: false,
            showResults: false,
            gpa: '',
            resident: false,
            results: [],
            scoreFormError: false,
        };

        this.handleCalculatorInputChange = this.handleCalculatorInputChange.bind(this);
        this.handleCalculate = this.handleCalculate.bind(this);
        this.calculateScholarships = this.calculateScholarships.bind(this);
        this.handleUserForm = this.handleUserForm.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }

    handleCalculatorInputChange(e) {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({ [e.target.name]: value });
    }

    handleCalculate(e) {
        e.preventDefault();
        if (this.state.gpa) {
            this.setState({
                showScoresForm: false,
                showUserForm: true,
            });
            this.calculateScholarships();
        } else {
            this.setState({
                scoreFormError: true
            })
        }
    }

    calculateScholarships() {
        let gpa = this.state.gpa;
        let resident = this.state.resident;

        const stateScholarships = {
            lifeScholarship: {
                name: "Life Scholarship",
                amount: 5000
            },
            lotteryTuition: {
                name: "Lottery Tuition Assistance Program",
                amount: 2400
            }
        }

        const results = {
            smc: null,
            state: false,
            total: null
        }

        if (gpa >= 4) {
            results.smc = 8000;
        } else if (gpa >= 3.75) {
            results.smc = 7000;
        } else if (gpa >= 3.5) {
            results.smc = 6500;
        } else if (gpa >= 3.25) {
            results.smc = 6000;
        } else if (gpa >= 3.0) {
            results.smc = 5500;
        } else if (gpa >= 2.0) {
            results.smc = 5000;
        }

        if (resident) {
            if (gpa >= 3.0) {
                results.state = stateScholarships.lifeScholarship;
            } else {
                results.state = stateScholarships.lotteryTuition;
            }
        }

        const totalAward = (results.smc ? results.smc : 0) + (results.state ? results.state.amount : 0);
        if (totalAward > 0) {
            results.total = totalAward.toLocaleString();
        }
        this.setState({ results: results });
    }

    handleUserForm(e) {
        e.preventDefault();
        this.props.handleSubmit(e);
        this.setState({
            showUserForm: false,
            showResults: true,
        })
    }

    handleReset() {
        this.setState({
            showScoresForm: true,
            showUserForm: false,
            showResults: false,
            results: [],
            gpa: '',
            resident: false,
        })
    }

    render() {
        return (
            <div className="calculator">
                <div className="calculator-wrapper">
                    {this.state.showScoresForm &&
                        <div className="calculator-intro">
                            <h2>Pioneered</h2>
                            <h3>To Be Affordable</h3>
                            <p>With SMC’s unmatched scholarship opportunities combined with federal and state aid,
                                tuition can be lower than many South Carolina public colleges. Use the calculator
                                below to crunch the numbers and see how much tuition relief you can get.</p>
                        </div>
                    }
                    {this.state.showUserForm &&
                        <div className="calculator-intro">
                            <h2>Almost There</h2>
                            <h3>Introduce Yourself</h3>
                            <p>Before we share your results, please complete the form below.</p>
                        </div>
                    }
                    {this.state.showScoresForm && <CalculatorScoresForm resident={this.state.resident} handleCalculate={this.handleCalculate} handleChange={this.handleCalculatorInputChange} error={this.state.scoreFormError} />}
                    {this.state.showUserForm &&
                        <UserFormInputs
                            formClass="user-form"
                            handleUserForm={this.handleUserForm}
                            user={this.props.user}
                            handleInputChange={this.props.handleInputChange}
                            buttonText="Results"
                        />
                    }
                    {this.state.showResults && <CalculatorResults results={this.state.results} handleReset={this.handleReset} />}
                    <ul className="step-indicator" aria-label="progress" tabIndex="-1">
                        <li aria-current={this.state.showScoresForm ? 'true' : ''}>Calculate</li>
                        <li aria-current={this.state.showUserForm ? 'true' : ''}>Introduce Yourself</li>
                        <li aria-current={this.state.showResults ? 'true' : ''}>Results</li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default ScholarshipCalculator;

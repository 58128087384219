import React from 'react';
import EnglishImage from "../../../images/on-campus_degrees/english.jpg";

const English = (props) => (
    <div>
        <div className="mb-8">
            <div className="offset-border-berry">
                <img src={EnglishImage} alt="Student reading a book" />
            </div>
        </div>
        <h2>English</h2>
        <p>The skillful use of language is highly valued in most work settings. The words we use, after all, have great power. They can influence important decisions, promote new ideas, and evoke intense emotions.</p>
        <p>An English concentration will expose you to a variety of texts and ideas from a diverse pool of authors, writers, playwrights and poets. Through in-depth study and classroom discussion, you’ll learn to appreciate and imitate various writing styles while clearly and creatively communicating your own ideas.</p>
        <h3>Why Study English?</h3>
        <p>You’ll develop a broad knowledge of important literature while you strengthen your self-expression skills. In addition, you’ll gain the valuable analytical and communication skills necessary to meet the demands of today’s workplace. Your ability to communicate clearly, think creatively and understand the importance of storytelling will make you an essential part of many organizations.</p>
        <h3>Find Your Dream Job</h3>
        <p>When you pair your degree with the internships, soft skills and career development training you'll get at SMC, you can major in almost any subject(s) you love and be ready for a great career in nearly any industry that interests you. Explore examples of careers available to students concentrating in English:</p>
        <ul>
            <li>Advertising or Marketing Manager</li>
            <li>Copywriter</li>
            <li>Content Strategist</li>
            <li>Editor</li>
            <li>Journalist</li>
            <li>Lawyer</li>
            <li>Librarian</li>
            <li>Teacher</li>
            <li>Technical writer</li>
            <li>Paralegal</li>
            <li>Public Relations Specialist</li>
        </ul>
        <div className="button-container">
            <h3>Want to learn more about SMC’s English degree?</h3>
            <a href="https://www.smcsc.edu/academics/degrees-programs/major-areas-of-study/english/" target="_blank" rel="noopener noreferrer"><button className="btn btn-primary btn-block" >Get More Info</button></a>
        </div>
    </div>
);

export default English

import React from 'react';
import BaCrimImage from "../../../images/online_degrees/ba-psychology.jpg";

const BAPsychology = () => (
    <div>
        <div className="mb-8">
            <div className="offset-border-berry">
                <img src={BaCrimImage} alt="Two detectives reviewing a file" />
            </div>
        </div>

        <h2>Bachelor of Arts - Psychology</h2>
        <p>The Bachelor of Arts - Psychology is a versatile online degree for students who are interested in a variety of fields that focus on the study of the human mind.</p>
        <p>For even more versatility, pair your psychology concentration with a concentration in either business or criminal justice. These combinations make your SMC bachelor’s degree even more relevant and valuable to employers in a wider range of career paths.</p>

        <h3>Why Get a Bachelor’s Degree in Psychology?</h3>
        <p>A psychology degree focuses on teaching you the inner workings of the mind, including behavior, cognitive, abnormal, and personality development. Psychology graduates have numerous options for their future career.</p>
        <p>Since our program is flexible and affordable, you can gain the tools you need to enhance your career without interrupting it.</p>

        <h3>Find Your Dream Job</h3>
        <p>Psychology degree graduates can pursue several career options, including:</p>
        <ul>
            <li>Counseling</li>
            <li>Health Services</li>
            <li>Social Work</li>
            <li>Victim's Advocate</li>
            <li>Human Resources</li>
        </ul>

        <div className="button-container">
            <h3>Want to learn more about SMC’s Online Bachelor of Arts - Psychology Degree?</h3>
            <a href="https://www.smcsc.edu/academics/spartanburg-methodist-college-online-degree-programs/online-bachelor-of-arts-criminal-justice-psychology-concentration/" target="_blank" rel="noopener noreferrer"><button className="btn btn-primary btn-block" >Get More Info</button></a>
        </div>
    </div>
);

export default BAPsychology;

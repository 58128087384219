import React, { Component } from 'react';
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react';

class SpartanburgMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedPlace: '',
            activeMarker: {},
            showingInfoWindow: false
        };

        this.handleMarkerClick = this.handleMarkerClick.bind(this);
    }

    handleMarkerClick(props, marker, e) {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        })
    }

    render() {
        return (
            <Map
                google={ this.props.google }
                zoom={ 13 }
                className="google-map"
                style={{ width: '100%', height: '100%', position: 'relative' }}
                initialCenter={{ lat: 34.951411, lng: -81.954009 }}>
                <Marker
                    onClick={ this.handleMarkerClick }
                    title={''}
                    name={'Spartanburg'}
                    position={{lat: 34.949149, lng: -81.930144 }} />

                <Marker
                    onClick={ this.handleMarkerClick }
                    title={''}
                    name={'Spartanburg Methodist College'}
                    position={{lat: 34.955722, lng: -81.971212 }} />

                <Marker
                    onClick={ this.handleMarkerClick }
                    title={''}
                    name={'Morgan Square'}
                    position={{lat: 34.949754, lng: -81.931734 }} />

                <Marker
                    onClick={ this.handleMarkerClick }
                    title={''}
                    name={'SMC Studio'}
                    position={{lat: 34.950674, lng: -81.929259 }} />

                <InfoWindow
                    marker={ this.state.activeMarker }
                    visible={ this.state.showingInfoWindow }>
                    <div>
                        <h3>{ this.state.selectedPlace.name }</h3>
                        <p>{ this.state.selectedPlace.title }</p>
                    </div>
                </InfoWindow>

            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyCjsO9lRMdkpkjjRWY3ZPL4_x4ajY4StfM'
})(SpartanburgMap);

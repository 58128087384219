import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import * as ROUTES from "../../constants/routes";
import './Campus.scss';
import HeroImage from "../../images/campus_living/hero.jpg"
import { ReactComponent as Fountain } from "../../images/campus_living/fountain.svg"
import { ReactComponent as ResidenceHallsStat } from "../../images/campus_living/residence-hall-stat.svg"
import { ReactComponent as StudentsStat } from "../../images/campus_living/live-on-campus-stat.svg"
import { ReactComponent as RatioStat } from "../../images/campus_living/male-to-female-ratio-stat.svg"
import { ReactComponent as TopoBlue } from "../../images/topographical-lines-blue.svg"
import ClubsImg from '../../images/campus_living/clubs.jpg';
import AthleticsImg from '../../images/campus_living/athletics.jpg';
import FacilitiesImg from '../../images/campus_living/facilities.jpg';

class Campus extends Component {
    render() {
        return (
            <main className="campus" id="main" tabIndex="-1">
                <Helmet>
                    <title>Campus | SMC Lookbook</title>
                    <meta name="description" content="An affordable, no-nonsense liberal arts education and a campus community
                        that gets you? Yes, please." />
                    <meta name="keywords" content="20:1 student faculty ratio, 7 campus residence halls, 49:51 male female ratio
                        , Intramural Sports, Campus Ministry, Honor Societies, Service Organizations, Publications/Literary
                        Organizations, Basketball, Cross Country, Golf, Soccer, Tennis, Softball, Volleyball, Baseball, Wrestling,
                        Campus facilities, The Moore Family Fitness Center, Starbucks" />
                    <link rel="canonical" href="https://makebigmoves.smcsc.edu/campus/living" />
                    <meta property="og:title" content="Campus | SMC Lookbook" />
                    <meta property="og:description" content="An affordable, no-nonsense liberal arts education and a campus
                        community that gets you? Yes, please." />
                    <meta property="og:image" content="/og-image-home.png" />
                    <meta property="og:url" content="https://makebigmoves.smcsc.edu/campus/living" />
                    <meta property="twitter:title" content="Campus | SMC Lookbook" />
                    <meta property="twitter:description" content="An affordable, no-nonsense liberal arts education and a campus
                        community that gets you? Yes, please." />
                    <meta property="twitter:image" content="/og-image-home.png" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>

                <div className="photo-hero-container mb-8">
                    <img src={HeroImage} alt="College student standing on campus" />
                    <div className="hero-topo">
                        <TopoBlue />
                    </div>
                </div>

                <div className="container text-center mb-8">
                    <div className="mb-4">
                        <h2>Campus &amp; Community</h2>
                        <h3>Friendships Are Forged Here</h3>
                    </div>
                    <p>An affordable, no-nonsense private college education and a campus community that gets you? Yes, please. Friendly and non-judgmental is just who we are, and that makes it easy for you to find friends and create lifelong connections.</p>
                </div>

                <div className="container text-center mb-8">
                    <h2>Living on Campus Offers Big Advantages</h2>
                    <h3>A Residential Life That Unites</h3>
                </div>

                <div className="items-row mb-8">
                    <div className="item stat">
                        <div className="figure"><ResidenceHallsStat /></div>
                        <p>Campus Residence Halls</p>
                    </div>
                    <div className="item stat">
                        <div className="figure"><StudentsStat /></div>
                        <p>of Students Live on Campus</p>
                    </div>
                    <div className="item stat">
                        <div className="figure">
                            <RatioStat />
                        </div>
                        <p>Male to Female Ratio</p>
                    </div>
                </div>

                <div className="topo mb-8">
                    <TopoBlue />
                </div>

                <div className="items-row text-center mb-8">
                    <div className="item">
                        <div className="offset-border-lime">
                            <img src={ClubsImg} alt="" />
                        </div>
                        <div className="campus-amenity-text">
                            <h3>General Interest Clubs</h3>
                            <p>Intramural Sports, Campus Ministry, Honor Societies, Service Organizations,
                                Publications/Literary Organizations</p>
                            <a
                                href={ROUTES.CLUBS}
                                target="_blank" rel="noopener noreferrer"
                                className="btn btn-primary">SMC Clubs</a>
                        </div>
                    </div>
                    <div className="item">
                        <div className="offset-border-berry">
                            <img src={AthleticsImg} alt="" />
                        </div>
                        <div className="campus-amenity-text">
                            <h3>NJCAA Division 1 Region 8 Conference Athletics</h3>
                            <p>Basketball, Cross Country, Half-Marathon, Golf, Soccer, Softball, Volleyball (Beach and Indoor), Baseball</p>
                            <a
                                href={ROUTES.PIONEERS}
                                target="_blank" rel="noopener noreferrer"
                                className="btn btn-primary">SMC Pioneers</a>
                        </div>
                    </div>
                    <div className="item">
                        <div className="offset-border-orange">
                            <img src={FacilitiesImg} alt="" />
                        </div>
                        <div className="campus-amenity-text">
                            <h3>Campus Facilities</h3>
                            <p>The Moore Family Fitness Center, Starbucks (of course), The
                                Ballard Center (perfect for handing out or meeting with career advisors)</p>
                            <a
                                href={ROUTES.VISIT}
                                target="_blank" rel="noopener noreferrer"
                                className="btn btn-primary">Schedule a Visit</a>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="fw-line-art p-1">
                        <Fountain />
                    </div>
                </div>
            </main>
        );
    }
}

export default Campus;

import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import '../OurDegrees.scss';
import HeroImage from "../../../images/on-campus_degrees/hero.jpg"
import JesicaHammImg from "../../../images/testimonials/jesica-hamm-headshot-blue-outline.png"
import { ReactComponent as TopoBerry } from "../../../images/topographical-lines-berry.svg"
import { ReactComponent as TopoLime } from "../../../images/topographical-lines-lime.svg"

import Concentrations from "../Concentrations";
import UserForm from '../../UserForm';
import Business from './Business';
import CriminalJustice from './CriminalJustice';
import English from './English';
import History from './History';
import Psychology from './Psychology';
import Religion from './Religion';

class OurDegrees extends Component {
    render() {
        let concentrations = [
            {name: "Business", component: <Business />},
            {name: "Criminal Justice", component: <CriminalJustice buttonLink="https://www.smcsc.edu/academics/degrees-programs/major-areas-of-study/criminal-justice/" />},
            {name: "English", component: <English />},
            {name: "Psychology", component: <Psychology />},
            {name: "History", component: <History />},
            {name: "Religion", component: <Religion/>},
        ];

        let concentrationIntro = () => {
            return (
                <div>
                    <h2>Customize Your SMC Bachelor’s Degree</h2>
                    <p>For your Bachelor’s Degree at SMC, you’ll choose to pursue two concentrations as part of our innovative career development curriculum.</p>
                </div>
            )
        }
        return (
            <main className="academics" id="main" tabIndex="-1">
                <Helmet>
                    <title>On Campus Degrees | SMC Lookbook</title>
                    <meta name="description" content="At SMC you have choices: 2-year or 4-year, on campus or online. With a range of degree options and concentrations at SMC you carve your own path." />
                    <meta name="keywords" content="2-year, two year, associate degrees, arts, business, criminal justice, fine
                        arts, religious studies, science, 4-year, four year, bachelor's degrees, business, english, history,
                        religion" />
                    <link rel="canonical" href="https://makebigmoves.smcsc.edu/academics/our-on-campus-degrees" />
                    <meta property="og:title" content="On Campus Degrees | SMC Lookbook" />
                    <meta property="og:description" content="At SMC you have choices: 2-year or 4-year, on campus or online. With a range of degree options and concentrations at SMC you carve your own path." />
                    <meta property="og:image" content="/og-image-home.png" />
                    <meta property="og:url" content="https://makebigmoves.smcsc.edu/academics/our-on-campus-degree" />
                    <meta property="twitter:title" content="On Campus Degrees | SMC Lookbook" />
                    <meta property="twitter:description" content="At SMC you have choices: 2-year or 4-year, on campus or online. With a range of degree options and concentrations at SMC you carve your own path." />
                    <meta property="twitter:image" content="/og-image-home.png" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>

                <div className="photo-hero-container mb-8">
                    <img src={HeroImage} alt="College students attending a graduation ceremony" />
                    <div className="hero-topo">
                        <TopoBerry />
                    </div>
                </div>

                <div className="container text-center mb-4">
                    <h2>Explore Our On Campus Degree Programs</h2>
                    <h3 className="mb-4">Earn two degrees in four years</h3>
                    <p>All colleges ask students to spend the first two years of a bachelor’s degree taking general education classes before choosing a major. You’ll be exposed to many different subjects so that you can pick the right major, concentration or specialization for your chosen career path.</p>
                    <p>SMC is different from other colleges because you’ll earn a degree for completing general college classes. This means when you finish your bachelor’s degree at SMC, you’ll have TWO degrees to show for it.</p>
                </div>

                <div className="topo mb-8">
                    <TopoLime />
                </div>

                <div className="container text-center mb-8">
                    <div className="mb-4">
                        <h2><span className="orange-slant">Bachelor’s Degrees</span></h2>
                        <p>SMC’s career-ready Bachelor’s Degree (B.A.) is designed to prepare you for careers available today AND in the future. Our B.A. program is unique because it allows you to choose two concentrations (similar to majors) so that you increase your academic knowledge and skills.</p>
                        <p>The B.A. program is available to students who have an Associate Degree or the equivalent in college course credit hours.</p>
                    </div>
                    <h4>Customizable Bachelor of Arts (Interdisciplinary Studies)</h4>
                    <h4 className="italic">Choose two concentrations/majors in:</h4>
                    <ul>
                        <li>Business</li>
                        <li>Criminal Justice</li>
                        <li>English</li>
                        <li>History</li>
                        <li>Psychology</li>
                        <li>Religion</li>
                    </ul>
                    <h4>Bachelor's Degree in Business Administration (BABA):</h4>
                    <h4 className="italic">Earn your business degree in one of these concentrations:</h4>
                    <ul>
                        <li>BABA in Accounting and Finance</li>
                        {/*<li>BABA in Economics and Finance</li>*/}
                        <li>BABA in Management and Entrepreneurship</li>
                        <li>BABA in Marketing and Sales</li>
                    </ul>
                </div>

                <Concentrations
                    kind="on-campus"
                    concentrations={concentrations}
                    concentrationIntro={concentrationIntro()}
                    dropdownLabel={"Learn about the areas of concentration"}
                />

                <UserForm
                    key="user-form"
                    introText="SMC just makes sense. We’re seeking students from all backgrounds. So, bring your strengths, your experiences and your passion and let’s make some BIG MOVES."
                    user={this.props.user}
                    handleSubmit={this.props.handleSubmit}
                    handleInputChange={this.props.handleInputChange}
                    submitError={this.props.submitError}
                    submitted={this.props.userFormSubmitted}
                />

                <div className="topo mb-8">
                    <TopoLime />
                </div>

                <div className="testimonial bg-berry white mb-8">
                    <img src={JesicaHammImg} alt="Jesica Hamm" />
                    <div>
                        <p className="testimonial-name">Jesica Hamm</p>
                        <p className="testimonial-class mb-4">SMC Class 2021</p>
                        <p className="testimonial-text mb-4">Using the writing and communications skills she learned through her English and history concentrations, Jesica obtained a summer internship in the marketing department at Polydeck — a leading provider of polyurethane and rubber screen media.</p>
                        <p className="testimonial-quote">“What I’ve liked most about my marketing internship is the constant growth I've had in an area I knew nothing about. SMC and Polydeck have continuously worked to find the best fit for me, which has allowed me strengthen myself professionally and further my goals.”</p>
                    </div>
                </div>
            </main>
        );
    }
}

export default OurDegrees;
